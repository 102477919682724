import React from 'react'
import { useState } from 'react'

function Dropdown4() {
  const [expandedSections, setExpandedSections] = useState({
    section1: false,
    section2: false,
    section3: false,
    section4: false,
    section5: false,
    section6: false,
    section7: false,
    section8: false,
    section9: false,
    section10: false,
    section11: false,
    section12: false,
    section13: false,
    section14: false,
    section15: false,
    section16: false,
    section17: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  return (
    <div>





      <div className='wesellList'>
        <ul>
          <li><p>We update our Gold Jewellery Collection after every 24 hours.</p></li>
          <li><p>We offer you new jewellery designs each day.</p></li>
          <li><p>But don’t worry if you like a piece and want to place an order later.</p></li>
          <li><p>You just have to provide us with a photo or the code of the jewellery you like.</p></li>
          <li><p>We shall get it customised for you, anytime you want.</p></li>
        </ul>
      </div>
      <div onClick={() => toggleSection('section1')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.1: What kind of jewellery do you sell?</div>
      <div className={expandedSections.section1 ? 'expanded' : 'collapsed'}>
        <p className='ml-4 pt-3'> We sell all kinds of Gold Jewellery including bridal sets, simple necklace, earrings, chains, rings, bangles, bracelets, anklets and ottiyanam.</p></div>

      <p onClick={() => toggleSection('section2')} className=' pb-4 pt-3' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.2: Do you take making charges for gold jewellery?
      </p>
      <div className={expandedSections.section2 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>No, we don’t take making charges for the ready-made jewellery you order from our catalogue. However, making charges are applicable on the customised jewellery.</p>
      </div>

      <p onClick={() => toggleSection('section3')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q3. Are all the jewellery you sell hallmarked?
      </p>
      <div className={expandedSections.section3 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. All the jewellery we are tested for purity and hallmarked.</p>
      </div>

      <p onClick={() => toggleSection('section4')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.4: Do you charge GST on purchasing gold jewellery?
      </p>
      <div className={expandedSections.section4 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. GST is applicable to purchase of both ready-made and custom-made gold jewellery.</p>
      </div>

      <p onClick={() => toggleSection('section5')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.5: Are the jewellery adjustable?
      </p>
      <div className={expandedSections.section5 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>
        Depends on the piece of jewellery. Some pieces can be adjusted as per your wish.
        </p>
      </div>

      <p onClick={() => toggleSection('section6')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.6: How will I know if the jewellery will fit me?
      </p>
      <div className={expandedSections.section6 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>You shall have to come to our office to be sure about the jewellery fittings.</p>
      </div>

      <p onClick={() => toggleSection('section7')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.7: Do you give purchase receipts?
      </p>
      <div className={expandedSections.section7 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. Purchase receipts are provided for both ready-made and custom-made gold jewellery.</p>
      </div>

      <p onClick={() => toggleSection('section8')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.8: Will there be any hidden costs?
      </p>
      <div className={expandedSections.section8 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'> No, there won’t be any hidden costs. All details shall be mentioned on the purchase receipt. </p>
      </div>
      <p onClick={() => toggleSection('section9')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.9: Do you offer Engraving Services?
      </p>
      <div className={expandedSections.section9 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes, we do offer engraving services on request.</p>
      </div>
      <p onClick={() => toggleSection('section10')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.10:  How Shall I collect the jewellery?
      </p>
      <div className={expandedSections.section10 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>You can collect your jewellery either from our office or we can deliver it to your doorstep.</p>
      </div>
      <p onClick={() => toggleSection('section11')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.11 What is your mode of delivery?
      </p>
      <div className={expandedSections.section11 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We have a team of trusted executives to safely deliver your gold jewellery to your doorstep.</p>
      </div>
      <p onClick={() => toggleSection('section12')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.12 Can I change the address of delivery after placing the order?
      </p>
      <div className={expandedSections.section12 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. You can change the address of delivery after you place your order.</p>
      </div>

      <p onClick={() => toggleSection('section13')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.13: How long will it take to deliver the jewellery?
      </p>
      <div className={expandedSections.section13 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We usually deliver ready-made gold jewellery safely to your doorstep within 24 to 48 hours after the order is placed. For custom-made jewellery, it takes us the same time to deliver but only after the jewellery is ready.</p>
      </div>

      <p onClick={() => toggleSection('section14')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.14: Do I need an ID Proof during collecting my jewellery?
      </p>
      <div className={expandedSections.section14 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>
        Yes, ID proof and an Address Proof are required while collecting the jewellery for verification purposes. 
        </p>
      </div>

      <p onClick={() => toggleSection('section15')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.15: Can my family members receive my order if I am not home?
      </p>
      <div className={expandedSections.section15 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes, your family members can collect the jewellery instead of you after clearing the payment. They will also require to provide us with an ID Proof and an Address Proof for verification purposes.</p>
      </div>

      <p onClick={() => toggleSection('section16')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.16: How shall I pay you?
      </p>
      <div className={expandedSections.section16 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>You can pay us as per your convenience. We accept cash/RTGS/IMPS/Net Banking payments.</p>
      </div>

      <p onClick={() => toggleSection('section17')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.17: Can I return the jewellery if I don’t like it after receiving?
      </p>
      <div className={expandedSections.section17 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes, you can return your jewellery with your and our mutual consent.</p>
      </div>

    </div>
  )
}

export default Dropdown4
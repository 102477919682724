import React from 'react';

function Topbar() {
  const iconStyle = {
    backgroundColor: 'transparent',
  };

  return (
    <>
      <div className='top-nav' style={iconStyle}>
        <div className="container">
          <div className="nav-social-container">
            <div className='nav-socail'>
            <a
              className="social-btns"
              href="https://www.facebook.com/sristargold"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i className="fab fa-facebook-f"></i>
            </a>

            <a
              className="social-btns"
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FSriStarGold"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i className="fab fa-twitter"></i>
              {/* <i className="fab fa-x"></i> */}

            </a>

            <a
              className="social-btns"
              href="https://in.pinterest.com/sristargold/"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i className="fab fa-pinterest"></i>
            </a>

            <a
              className="social-btns"
              href="https://www.instagram.com/sristargold/"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i className="fab fa-instagram"></i>
            </a>

            <a
              className="social-btns"
              href="https://www.youtube.com/channel/UC7xKHOdqDLvlnynflYNBdew/featured"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i className="fab fa-youtube"></i>
            </a>

            <a
              className="social-btns"
              href="https://wa.me/6366333444"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
            </div>
            <span className='gold-today-price'>
              {/* <p>Today's Rate : Gold 22k/1 g Rs5735</p> */}
              <a style={{textDecoration:'none', color:'inherit',fontWeight:'500'}} href='tel:6366333444'><i class="fa-solid fa-phone"></i> 6366333444</a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Topbar;

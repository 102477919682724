import React from 'react'

function Company() {
  return (
    <div className='banner-content'>
      <h1>Welcome To SriStar Gold <br />
        Company</h1>
      <p>
        SriStar Gold Company is the one-stop-solution if you are in need of quick cash or
        looking for profitable investments. We offer you the best deals because we
        understand your old and other precious metals are very important to you
      </p>
      <div className='banner-foot'>
        <a href='/about'>
          <button className="btn btn-home" type="button">About Us</button></a>
        <a href='tel:6366333444'>
          <button className="btn btn-home" type="button">Contact Us</button></a>
      </div>

    </div>
  )
}

export default Company
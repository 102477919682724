import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import malavika from "../Images/malavika.jpg"
import sudharani from "../Images/instant-cash-for-gold.jpg"
import Sliding from './Sliding';
function Slider() {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1
  //   }
  // };
  return (
    <div className='container'>

      <div className='sub-hading'>
        <h2 className='section-sub-heading'>NEWS  &  PROMOTIONS</h2>
      </div>
      <section className='carausel-main-section'>

        <div className='container'>
          <div id="carouselExampleControls" className="carousel slide">
            <div className="carousel-inner " >
              <div className="carousel-item active" >
                <img className="img-fluid" src={sudharani} alt="First slide" />
                <div className="carousel-caption ">
                  <p className=''>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
                </div>
              </div>
              <div className="carousel-item" >
                <img className="img-fluid" src={malavika} alt="Second slide" />
                <div className="carousel-caption ">
                  <p className=''>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
                </div>
              </div>
              {/* <div className="carousel-item" >
                <img className="img-fluid" src={sudharani} alt="Third slide" />
                <div className="carousel-caption ">
                  <p className=''>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
                </div>
              </div>
              <div className="carousel-item" >
                <img className="img-fluid" src={malavika} alt="Fourth slide" />
                <div className="carousel-caption  ">
                  <p className=''>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
                </div>
              </div> */}
            </div>


            {/* <div className="carousel-inner d-block d-md-none" style={{ width: "100%" }}>
      <div className="carousel-item active" style={{ width: "100%" }}>
        <img className="d-block w-100" src={sudharani} alt="First slide" />
        <div className="carousel-caption " style={{ left: "10px" }}>
          <p className='text-white  text-left ' style={{ lineHeight: "17px", top: "0px", position: "absolute", }}>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
        </div>
      </div>
      <div className="carousel-item" style={{ width: "100%" }}>
        <img className="d-block w-100" src={malavika} alt="Second slide" />
        <div className="carousel-caption " style={{ left: "10px" }}>
          <p className='text-white text-left ' style={{ lineHeight: "17px", top: "0px", position: "absolute", }}>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
        </div>
      </div>
      <div className="carousel-item" style={{ width: "100%" }}>
        <img className="d-block w-100" src={sudharani} alt="Third slide" />
        <div className="carousel-caption " style={{ left: "10px" }}>
          <p className='text-white text-left' style={{ lineHeight: "17px", top: "0px", position: "absolute", }}>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
        </div>
      </div>
      <div className="carousel-item" style={{ width: "100%" }}>
        <img className="d-block w-100" src={malavika} alt="Fourth slide" />
        <div className="carousel-caption " style={{ left: "10px" }}>
          <p className='text-white text-left ' style={{ lineHeight: "17px", top: "0px", position: "absolute", }}>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
        </div>
      </div>
    </div> */}

            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

      </section>
      {/* <Sliding /> */}
      {/* <Carousel responsive={responsive}
      
      >
      <div style={{width:"95%"}}>                  
            <img src={sudharani} alt="First slide" className='w-100' style={{ height: "290px"}} />
          <div className="col-md-8 carousel-caption  text-justify cursor-default" style={{ padding: "0px", margin: "0px", left: "10px", fontSize: "12px" }}>
            <p style={{fontWeight:"700", width:"90%"}}>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
          </div>
        </div>
        <div style={{width:"95%"}}>                  
           <img src={malavika} alt="First slide" className='w-100' style={{ height: "290px"}} />
           <div className="col-md-8 carousel-caption text-justify cursor-default" style={{ padding: "0px", margin: "0px", left: "10px", fontSize: "12px" }}>
            <p style={{fontWeight:"700", width:"90%"}}>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
          </div>
        </div>
        <div style={{width:"95%"}}>                   
            <img src={sudharani} alt="First slide" className='w-100' style={{ height: "290px"}} />
          <div className="col-md-8 carousel-caption  text-justify cursor-default" style={{ padding: "0px", margin: "0px", left: "10px", fontSize: "12px" }}>
            <p style={{fontWeight:"700", width:"90%"}}>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
          </div>
        </div>
        <div style={{width:"95%"}}>                   
           <img src={malavika} alt="First slide" className='w-100' style={{ height: "290px"}} />
           <div className="col-md-8 carousel-caption text-justify cursor-default" style={{ padding: "0px", margin: "0px", left: "10px", fontSize: "12px" }}>
            <p style={{fontWeight:"700", width:"90%"}}>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
          </div>
        </div>
      </Carousel>; */}

    </div>
  )
}

export default Slider
import React from 'react'
// import GoldReturnsCardbg from "../Images/goldReturnCardbg.png"
import goldcard from "../Images/goldReturnCard.png"
import Navbars from '../Utility/Navbars'
import chaitheory from "../Images/chai-theory.jpeg"
import aslikahani from "../Images/aslikahani.png"
import digitalEngine from "../Images/digital-engine.jpeg"
import cardImg from "../Images/card.jpg"
import { Card } from 'react-bootstrap'
import Footer from '../Home/Footer'


function GoldReturnsCard() {
    return (
        <>
            <Navbars />
            <div className='inner-pages'>
                <div class="sub-hading"><h2>GOLD <strong>RETURNS</strong> CARD
                </h2></div>

                <div className='row'>
                    <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
                        <div className='bg-white'>
                            <h6 className='text-center font-weight-bolder p-4'>Discount on Gold Purchase</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>With the Gold Return Card, you can avail an exclusive discount of Rs 50 per gram when buying gold from us. This means you can expand your gold collection while keeping more money in your pocket.</p>
                            </div>

                            <br />
                            <h6 className='text-center font-weight-bolder p-4'>Better Rates for Selling Gold</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>When you decide to part with your precious gold, the Gold Return Card rewards you with an additional Rs 50 per gram, ensuring that you get the best deal in town.</p>
                            </div>

                            <br />
                            <h6 className='text-center font-weight-bolder p-4'>Wastage Charges Waived</h6>
                            <div className='col-md-10 mx-auto '>
                                <p className='text-center p-2' style={{ fontSize: "12px", marginBottom: "55px" }}>Over the next four years, you can buy gold from us without incurring any wastage charges, adding significant value to every gram of gold you purchase.
                                    {/* <br/> */}
                                </p>
                            </div>
                            <br />
                            <br />
                            <br />
                            {/* <br/>
                        <br/> */}
                        </div>
                    </div>

                    {/* mobile view */}
                    <div className='col-md-4 d-block d-md-none' style={{ padding: "initial" }}>
                        <div className='bg-white'>
                            <h6 className='text-center font-weight-bolder pt-3'>Discount on Gold Purchase</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>With the Gold Return Card, you can avail an exclusive discount of Rs 50 per gram when buying gold from us. This means you can expand your gold collection while keeping more money in your pocket.</p>
                            </div>

                            <br />
                            <h6 className='text-center font-weight-bolder '>Better Rates for Selling Gold</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>When you decide to part with your precious gold, the Gold Return Card rewards you with an additional Rs 50 per gram, ensuring that you get the best deal in town.</p>
                            </div>

                            <br />
                            <h6 className='text-center font-weight-bolder'>Wastage Charges Waived</h6>
                            <div className='col-md-10 mx-auto '>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>Over the next four years, you can buy gold from us without incurring any wastage charges, adding significant value to every gram of gold you purchase.

                                </p>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>

                    <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
                        <br /><br /><br />
                        <div className='mt-2 mb-4'>
                            <img src={goldcard} className='image-responsive w-100' />
                        </div>
                    </div>

                    {/* mobile view */}
                    <div className='col-md-4 d-block d-md-none' style={{ padding: "initial" }}>
                        <div className='mt-4 mb-4'>
                            <img src={goldcard} className='image-responsive w-100' />
                        </div>
                    </div>

                    <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
                        <div className='bg-white'>
                            <h6 className='text-center font-weight-bolder p-4'>Re-Pledging Advantages</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>We will cover the cost of your pledged gold and assist you in transferring it to another institution that offers a higher return rate.</p>
                            </div>

                            <br />
                            <h6 className='text-center font-weight-bolder p-4'>Making charges waived</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>Over the upcoming four years, you have the opportunity to purchase gold from us without incurring any production fees, which enhances the overall worth of each gram of gold you buy.</p>
                            </div>

                            <br />
                            <h6 className='text-center font-weight-bolder p-4'>Chit Fund Schemes</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2 mb-5' style={{ fontSize: "12px" }}>The Gold Returns Card also opens doors to our chit fund schemes, where you can participate by paying a monthly fee ranging from Rs 500 to 10,000. By joining these schemes, you unlock monthly discounts and lucrative rewards, making your gold investment even more profitable.</p>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>

                    {/* Mobile View */}
                    <div className='col-md-4 d-block d-md-none' style={{ padding: "initial" }}>
                        <div className='bg-white'>
                            <h6 className='text-center font-weight-bolder pt-3'>Re-Pledging Advantages</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>We will cover the cost of your pledged gold and assist you in transferring it to another institution that offers a higher return rate.</p>
                            </div>

                            <br />
                            <h6 className='text-center font-weight-bolder '>Making charges waived</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2' style={{ fontSize: "12px" }}>Over the upcoming four years, you have the opportunity to purchase gold from us without incurring any production fees, which enhances the overall worth of each gram of gold you buy.</p>
                            </div>

                            <br />
                            <h6 className='text-center font-weight-bolder '>Chit Fund Schemes</h6>
                            <div className='col-md-10 mx-auto'>
                                <p className='text-center p-2 ' style={{ fontSize: "12px" }}>The Gold Returns Card also opens doors to our chit fund schemes, where you can participate by paying a monthly fee ranging from Rs 500 to 10,000. By joining these schemes, you unlock monthly discounts and lucrative rewards, making your gold investment even more profitable.</p>
                            </div>
                        </div>
                    </div>

                    <div className='bg-white position-relative' style={{ top: "-24px" }}>
                        <div className='col-md-12 '>
                            <div className='my-5'>
                                <div className='row'>

                                    <div className='col-md-4'>
                                        <h6 className='text-center font-weight-bolder '>Free Consultation</h6>
                                        <div className='col-md-12 mx-auto'>
                                            <p className='text-center p-2' style={{ fontSize: "12px" }}>Whether you are looking for job offers or a boost in your business, our Gold Returns Card<br />
                                                offers free consultation services and financial assistance*, ensuring you make the right moves in your career and financial endeavors.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <h6 className='text-center font-weight-bolder '>EMI Benefits</h6>
                                        <div className='col-md-12 mx-auto'>
                                            <p className='text-center p-2' style={{ fontSize: "12px" }}>We understand that gold investments may require flexible payment options. However with the Gold Returns Card, you can buy gold on EMI. Pay for 11 months and the final EMI is on us, making it convenient and affordable to own gold.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <h6 className='text-center font-weight-bolder '>Referral Commissions</h6>
                                        <div className='col-md-12 mx-auto'>
                                            <p className='text-center p-2' style={{ fontSize: "12px" }}>Share the benefits of the Gold Returns Card with your friends and family, and we'll reward you with handsome commissions; Rs50/gm* for every successful referral. It's a win-win for everyone!</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <h2 className='text-center text-uppercase font-weight-bolder' style={{ color: "#441e39", letterSpacing: "1px", fontFamily: "sans-serif" }}>AND OTHER ADDITIONAL BENEFITS</h2>
                        <div className='col-md-12 text-center'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-4 p-4'>
                                        <img src={chaitheory} className='image-responsive w-100' />
                                        <p className='text-center font-weight-bold mt-3'>Discount on Chai Theory</p>
                                        <p style={{ fontSize: "12px" }}>We value your association with us, and as a Gold Returns Cardholder, you can enjoy exclusive discounts upto 50%* when indulging in our unique chai theory experiences. Sip your tea with a touch of gold.</p>
                                    </div>
                                    <div className='col-md-4 p-4'>
                                        <img src={digitalEngine} className='image-responsive w-100' />
                                        <p className='text-center font-weight-bold mt-3'>Discount in Digital Engine</p>
                                        <p style={{ fontSize: "12px" }}>As the world advances digitally, so do we! With the Gold Returns Card, you get discounts upto 30%* on our digital services and platforms, ensuring that you stay ahead in the ever evolving world of gold investments.</p>
                                    </div>
                                    <div className='col-md-4 p-4'>
                                        <img src={aslikahani} className='image-responsive w-100' />
                                        <p className='text-center font-weight-bold mt-3'>Promotion in Asli Kahani Magazine</p>
                                        <p style={{ fontSize: "12px" }}>Your association with us is more than just a card. As a cardholder, you can avail a discount upto 50%* on getting featured in our renowned Asli Kahani Magazine, where your story can reach a broader audience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h2 className='text-uppercase font-weight-bold mt-4 p-5' style={{ color: "#441e39", fontFamily: "sans-serif" }}>What Are You Waiting For ?</h2>
                                    <div className='card-button ml-5 mb-3'>
                                        <a href='mailto: sristargoldcompany@gmail.com' className='text-decoration-none'>
                                            <button type="submit" className="btn bg-primary font-weight-bold " style={{ fontSize: "12px", height: "35px", width: "112px", border: "2px solid #000000" }}>

                                                Apply Now

                                            </button> </a>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <img src={cardImg} className='w-100' alt='card-img' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default GoldReturnsCard
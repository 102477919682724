// import React from 'react'
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import middle from "../Images/middle.jpg"
// import sudharani from "../Images/instant-cash-for-gold.jpg"
// import malavika from "../Images/malavika.jpg"

// function NewsSlider() {
//   const responsive = {
//     superLargeDesktop: {
//       // the naming can be any, depends on you.
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 3
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1
//     }
//   };
//   return (
//     <div>
//         <div className='container'>
//       <Carousel responsive={responsive}>
//       <div>                  
//             <img src={sudharani} alt="First slide" style={{ height: "300px", width: "350px" }} />
//           <div className="carousel-caption  text-justify cursor-default" style={{ padding: "0px", margin: "0px", left: "10px", fontSize: "12px" }}>
//             <p style={{fontWeight:"700"}}>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
//           </div>
//         </div>
//         <div>                   
//            <img src={malavika} alt="First slide" style={{ height: "300px", width: "350px" }} />
//            <div className="carousel-caption  text-justify cursor-default" style={{ padding: "0px", margin: "0px", left: "10px", fontSize: "12px" }}>
//             <p style={{fontWeight:"700"}}>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
//           </div>
//         </div>
//         <div>                  
//             <img src={sudharani} alt="First slide" style={{ height: "300px", width: "350px" }} />
//           <div className="carousel-caption  text-justify cursor-default" style={{ padding: "0px", margin: "0px", left: "10px", fontSize: "12px" }}>
//             <p style={{fontWeight:"700"}}>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
//           </div>
//         </div>
//         <div>                   
//            <img src={malavika} alt="First slide" style={{ height: "300px", width: "350px" }} />
//            <div className="carousel-caption  text-justify cursor-default" style={{ padding: "0px", margin: "0px", left: "10px", fontSize: "12px" }}>
//             <p style={{fontWeight:"700"}}>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
//           </div>
//         </div>
//       </Carousel>;
//       </div>
//     </div>
//   )
// }

// export default NewsSlider

import React from 'react'
import sudharani from "../Images/2.1.jpg"
import malavika from "../Images/1.1.jpg"

function NewsSlider() {
  return (
    <div>
        <div className='col-md-11 mx-auto'>
      <div id="carouselExampleControls" className="carousel slide">
  <div className="carousel-inner" style={{width:"100%", margin:"auto"}}>
    <div className="carousel-item active" style={{width:"100%"}}>
      <img className="d-block w-100" src={sudharani} alt="First slide"/>
      <div className="carousel-caption d-none d-md-block">
    <p className='text-white h5 text-justify font-weight-bold'>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
    </div>
    <div className="carousel-caption d-block d-md-none"  style={{left:"10px", zIndex:"auto"}}>
    <p className='text-white  text-left ' style={{lineHeight:"17px", top:"0px", position:"absolute", }}>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
    </div>
    </div>
    <div className="carousel-item" style={{width:"100%"}}>
      <img className="d-block w-100" src={malavika} alt="Second slide"/>
      <div className="carousel-caption d-none d-md-block">
    <p className='text-white h5 text-justify font-weight-bold'>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
    </div>
    <div className="carousel-caption d-block d-md-none"  style={{left:"10px", zIndex:"auto"}}>
    <p className='text-white  text-left ' style={{lineHeight:"17px", top:"0px", position:"absolute", }}>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
    </div>
    </div>
    <div className="carousel-item" style={{width:"100%"}}>
      <img className="d-block w-100" src={sudharani} alt="Third slide"/>
      <div className="carousel-caption d-none d-md-block">
    <p className='text-white h5 text-justify font-weight-bold'>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
    </div>
    <div className="carousel-caption d-block d-md-none"  style={{left:"10px", zIndex:"auto"}}>
    <p className='text-white  text-left ' style={{lineHeight:"17px", top:"0px", position:"absolute", }}>Cash on hand is handier during a financial crunch, instant cash for gold is a favorable choice</p>
    </div>
    </div>
    <div className="carousel-item" style={{width:"100%"}}>
      <img className="d-block w-100" src={malavika} alt="Fourth slide"/>
      <div className="carousel-caption d-none d-md-block ">
    <p className='text-white h5 text-justify font-weight-bold'>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
    </div>
    <div className="carousel-caption d-block d-md-none"  style={{left:"10px", zIndex:"auto"}}>
    <p className='text-white  text-left ' style={{lineHeight:"17px", top:"0px", position:"absolute", }}>Top gold-silver jewelry-buying & selling companies in India have a few common traits</p>
    </div>
    </div>
  </div>
  
  <br/>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" style={{ zIndex:"auto"}}>
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" style={{ zIndex:"auto"}}>
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>

</div>

    </div>
  )
}

export default NewsSlider
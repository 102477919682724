import React from 'react'
import { useState } from 'react';
import swal from 'sweetalert';

function PartnerForm() {

    const [spinner, setSpinner] = useState(false);
    // const navigate = useNavigate();
    const [formData, setFormData] = useState({
        full_name: '',
        phone_number: '',
        email: '',
        total_funds_available: '',
        funds_in_hand_to_invest: '',
        funds_required_to_borrow: '',
        source_of_borrow: '',
        previous_experience: '',
        area_of_interest: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSpinner(true);
        const result = formData;
        console.log(result)

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(formData),
        };

        try {

            const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/leads/franchise/submit`, requestOptions);
            setSpinner(false);
            if (response.ok) {
                swal({
                    title: "Query Submitted",
                    text: "Team will get back to you shortly",
                    icon: "success"
                })

                console.log('Form data submitted successfully!', response);
            }
            else {
                swal({
                    title: "Failed!!!",
                    text: "Please check your details and try again",
                    icon: "error"
                })
            }
        } catch (error) {

            console.error('Error submitting form data:', error);
        }
    };


    return (
        <>
            <section className='application-section'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-md-8 mx-auto'>
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="col-md-6 form-group">

                                        <div className="form-input">
                                            <label className='label-partners' for="name" >Full Name  <span>*</span></label>
                                            <input type="text" id="full_name"
                                                name='full_name'
                                                value={formData.full_name}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div>

                                    <div className="col-md-6 form-group">

                                        <div className="form-input">
                                            <label className='label-partners' for="email">Phone <span>*</span></label>
                                            <input type="number" id="phone"
                                                name='phone_number'
                                                value={formData.phone_number}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div>


                                    <div className="col-md-12 form-group">

                                        <div className="form-input">
                                            <label className='label-partners' for="city">Email <span>*</span></label>
                                            <input type="email" id="email"
                                                name='email'
                                                value={formData.email}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <div className="form-input">
                                            <label className='label-partners' for="city">Total Fund Available For Invest <span>*</span></label>
                                            <input type="text" id="funds"
                                                name='total_funds_available'
                                                value={formData.total_funds_available}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <div className="form-input">
                                            <label className='label-partners' for="city">Fund In Hand Available To Invest <span>*</span></label>
                                            <input type="text" id="funds"
                                                name='funds_in_hand_to_invest'
                                                value={formData.funds_in_hand_to_invest}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 form-group">
                                        <div className="form-input">
                                            <label className='label-partners' for="city">Fund Required For Borrow <span>*</span></label>
                                            <input type="text"  id="fund"
                                                name='funds_required_to_borrow'
                                                value={formData.funds_required_to_borrow}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <div className="form-input">
                                            <label className='label-partners' for="city">Source Of Borrow <span>*</span></label>
                                            <input type="text"  id="source"
                                                name='source_of_borrow'
                                                value={formData.source_of_borrow}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 form-group">
                                        <div className="form-input">
                                            <label className='label-partners' for="city">Current Or Previous Experience <span>*</span></label>
                                            <input type="text" id="current"
                                                name='previous_experience'
                                                value={formData.previous_experience}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <div className="form-input">
                                            <label className='label-partners' for="city" >Areas Of Interests (In Which You Would Like To Open A Store) <span>*</span></label>
                                            <input type="text" id="area"
                                                name='area_of_interest'
                                                value={formData.area_of_interest}
                                                onChange={handleChange}
                                                style={{ height: "60px" }} required />
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='form-submit'>
                                            <button type="submit"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PartnerForm
import React from 'react'
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import Home from '../Navpages/Home'
import About from '../Navpages/About'
import Calculator from '../Navpages/Calculator'
import Branches from '../Navpages/Branches'
import Partners from '../Navpages/Partners'
import News from '../Navpages/News'
import Services from '../Navpages/Services'
import Faq from '../Navpages/Faq'
import GoldReturnsCard from '../Navpages/GoldReturnsCard'
import Career from '../Navpages/Career'

function Router() {
  return (
    <>
     <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<Home /> }/>
            <Route path="/home" exact element={<Home />}/>
            <Route path="/about" exact element={<About />}/>
            <Route path="/calculator" exact element={<Calculator />}/>
            <Route path="/branches" exact element={<Branches />}/>
            <Route path="/partner" exact element={<Partners />}/>
            <Route path="/news" exact element={<News />}/>
            <Route path="/services" exact element={<Services />}/>
            <Route path="/faq" exact element={<Faq /> }/>
            <Route path="/gold-returns-card" exact element={<GoldReturnsCard /> }/>
            <Route path="/career" exact element={<Career /> }/>
            {/* <Route path="/contact" exact element={<Services />}/> */}
            </Routes>
            </BrowserRouter>
    
    </>
  )
}

export default Router
import React from 'react'
import Navbars from '../Utility/Navbars'
import AboutPage from '../About/AboutPage'
import History from '../About/History'
import OurVision from '../About/OurVision'
import Footer from '../Home/Footer'

function About() {
  return (
    // <div>
    //   <div className='bg-about'>
    //     <Navbars />

    //     <AboutPage />

    //   </div>
    //   <History />
    //   <div className='vision' style={{ backgroundColor: "rgb(227,227,227)" }}>
    //     <OurVision />
    //   </div>
    //   <Footer />
    // </div>
    <>
      <Navbars />

      <div className='inner-pages'>
          <AboutPage />
          <History />
          <OurVision />

      </div>
      <Footer />
    </>
  )
}

export default About
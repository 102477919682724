import React from 'react'
import Navbars from '../Utility/Navbars'
import Footer from '../Home/Footer'
import Questions from '../Home/Questions'

function Faq() {
  return (
    <>
      <Navbars />
      <div className='inner-pages'>
        <div class="sub-hading"><h2>Frequently Asked Questions</h2></div>

        <Questions />

      </div>
      <Footer />
    </>

  )
}

export default Faq
import React from 'react'
import gold from "../Images/gold.png"
import Dots from "../Images/Icons/rect.webp"

function History() {
    return (
        // <div>
        //     <div className='container-fluid'>
        //         <div className='row'>
        //             <div className='col-md-6' style={{padding: "initial"}}>
        //                 <img src={gold} alt='gold' className='image-responsive' style={{width:"100%", height:"100%"}}/>
        //             </div>
        //             <div className='col-md-6' style={{backgroundColor:"rgb(180,165,176)"}}>
        //                 <div className='info'>
        //                     <h3>History</h3>
        //                     <ul className='info-lists'>
        //                         <li className='mb-1'>We have successfully launched 15+ branches in South India within just the first 6 months of our operations. This has only been possible for us because of your love and support. We hope to spread our services all over India soon.</li>

        //                        <li className='mb-1'>We are the only gold company in history to provide maximum cash for gold and other precious metals. We offer up to ₹10,000 extra for your metals than the prices online. Because we know how valuable your metals are to you.</li>

        //                        <li className='mb-1'>Since the beginning of our journey, we have given employment opportunities to 200+ people all across India.</li>

        //                        <li className='mb-1'>We have always maintained the male to female staff ratio in our company. In fact, 60% of our staff are women, making us diverse and efficient</li>
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <section className='history-section'>
            <div className='container'>
                <div className="sub-hading"><h2 className="section-sub-heading">HISTORY</h2></div>
                <div className='history-content'>
                    <div className='hst-img'>
                        <span className='rect-dots-top'>
                            <img className='img-fluid' src={Dots} />
                        </span>
                        <img src={gold} alt='gold' className='img-fluid' />
                        <span className='rect-dots-bottom'>
                            <img className='img-fluid' src={Dots} />
                        </span>
                    </div>
                    <div className='hst-right'>
                        <ul>
                            <li><p>We have successfully launched 15+ branches in South India within just the first 6 months of our operations. This has only been possible for us because of your love and support. We hope to spread our services all over India soon.</p></li>
                            <li><p>We are the only gold company in history to provide maximum cash for gold and other precious metals. We offer up to ₹10,000 extra for your metals than the prices online. Because we know how valuable your metals are to you.</p></li>
                            <li><p>Since the beginning of our journey, we have given employment opportunities to 200+ people all across India.</p></li>
                            <li><p>We have always maintained the male to female staff ratio in our company. In fact, 60% of our staff are women, making us diverse and efficient</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default History
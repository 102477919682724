import React from 'react'
import Navbars from '../Utility/Navbars'
import Services from '../Home/Services'
import create from "../Images/create.png"
import ApplyNow from '../Home/ApplyNow'
// import Slider from '../Home/Slider'
import Footer from '../Home/Footer'
import Marquee from '../Home/Marquee'
import Company from '../Home/Company'
import CardSlider from '../Home/CardSlider'
import goldVideo from "../Images/video.mp4"
import { useState } from 'react'

function Home() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  return (
    <>
      <Navbars />
      <section className="showcase" >
        <video className='banner-video'
          loop
          muted
          autoPlay={isVideoPlaying}
          playsInline
          webkit-playsInline
        // style={{ height: '590px', width: '100%', objectFit: 'cover' }}
        >
          <source src={goldVideo} type="video/mp4" />
        </video>
        <div className="overlay">
        </div>
        <Company />

      </section>
      <Services />
      <section className='full-width-calculate'>
        <img src={create} alt='full-img' className="img-fluid img-cal" />
        <img src={create} alt='full-img' className="img-fluid img-cal-mob" />
        <div className='calculate-now-btn'> <a href='/calculator' className='text-decoration-none'>Calculate Now</a></div>
      </section>
      <ApplyNow />
      <CardSlider />
      <Footer />
      <Marquee/>
    </>
  )
}

export default Home
import React from 'react'
import { useState } from 'react';

function Dropdown2() {

    const [expandedSections, setExpandedSections] = useState({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: false,
        section6: false,
        section7: false,
        section8: false,
        section9: false,
        section10: false,
        section11: false,
        section12: false,       
      });
    
      const toggleSection = (section) => {
        setExpandedSections((prev) => ({
          ...prev,
          [section]: !prev[section],
        }));
      };
    
  return (
    <div>
        
        <div onClick={() => toggleSection('section1')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
        Q.1: How many days before my event should I book the jewellery?</div>
      <div className={expandedSections.section1 ? 'expanded' : 'collapsed'}>
        <p className='ml-4 pt-3'>You have to book the jewellery 3 days before you want to wear it.</p></div>

      <p onClick={() => toggleSection('section2')} className=' pb-4 pt-3' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.2: How long does it take to receive the jewellery after booking?
      </p>
      <div className={expandedSections.section2 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>After your booking is confirmed, it will take another 24 to 48 hours to deliver the jewellery.</p>
      </div>

      <div onClick={() => toggleSection('section3')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.3: What are the minimum and maximum timespan of rental?</div>
      <div className={expandedSections.section3 ? 'expanded' : 'collapsed'}>
        <p className='ml-4 pt-3'>You can rent jewellery from us for 1 to 3 days (depending on the cases).</p></div>

      <p onClick={() => toggleSection('section4')} className=' pb-4 pt-3' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.4: What is your rate of jewellery rental?
      </p>
      <div className={expandedSections.section4 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>You will have to pay 2% of the gold value on each day of rental.</p>
      </div>

      <div onClick={() => toggleSection('section5')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.5: Do I have to pay a security deposit to rent jewellery?</div>
      <div className={expandedSections.section5 ? 'expanded' : 'collapsed'}>
        <p className='ml-4 pt-3'>There is no provision of any security deposit yet. But, we will carry out a verification profiling on you. On clearing the verification profiling you will become eligible to rent gold from us.</p></div>

      <p onClick={() => toggleSection('section6')} className=' pb-4 pt-3' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.6: When and How shall I pay for the jewellery rental?
      </p>
      <div className={expandedSections.section6 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>You can pay us as per your convenience. We accept cash/RTGS/IMPS/Net Banking payments.</p>
      </div>

      <p onClick={() => toggleSection('section7')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.7: How Will I receive and return the rented jewellery?
      </p>
      <div className={expandedSections.section7 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We deliver the gold jewellery to your doorstep. But, you shall have to come to our office at the time of return.</p>
      </div>
      <p onClick={() => toggleSection('section8')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.8: Do I need an ID Proof during collecting my jewellery?
      </p>
      <div className={expandedSections.section8 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes, ID proof and an Address Proof are required while collecting the jewellery for verification purposes.</p>
      </div>
      <p onClick={() => toggleSection('section9')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.9: Are the jewellery adjustable?
      </p>
      <div className={expandedSections.section9 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Depends on the piece of jewellery. Some pieces can be adjusted as per your wish.</p>
      </div>
      <p onClick={() => toggleSection('section10')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.10: How will I know if the jewellery will fit me?
      </p>
      <div className={expandedSections.section10 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>You shall have to come to our office to be sure about the jewellery fittings.</p>
      </div>
      <p onClick={() => toggleSection('section11')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.11: Can I buy the jewellery I have rented?
      </p>
      <div className={expandedSections.section11 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes, of course! You will have to visit our office for the payment procedure.</p>
      </div>
      <p onClick={() => toggleSection('section12')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
      Q.12: What will happen if the rented jewellery gets damaged or stolen?
      </p>
      <div className={expandedSections.section12 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>If the jewellery you rented becomes damaged, or gets stolen, we will consider it sold, and you shall have to pay the actual amount.</p>
      </div>    
    </div>
  )
}

export default Dropdown2
import React from 'react';
import Footerlogo from "../Images/web.png"

function Footer() {
  return (
    <div>
      <div className='foot-bg'>
      <div className="container " >
        <footer className="text-lg-start" >
          <div className="container p-2 pb-0">
            <section>
              <div className="row">
                <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-4">
                  <h6 className="text-uppercase mb-4 font-weight-bold pt-3 formobicon">
                    <a href='/'>
                    <img src={Footerlogo} className='image-fluid' /></a>
                  </h6>
                  <p style={{fontSize:"12px", fontWeight:"bolder"}}>
                  Corp. Off: 280/1, Anniamma Arcade, Sampige Road, 18th Cross Rd, Malleshwaram, Bengaluru, Karnataka 560003
                  </p>
                  <p style={{fontSize:"12px", fontWeight:"bolder"}}>Contact: 6366333444</p>
                </div>

                {/* <hr className="w-100 clearfix d-md-none" /> */}
                <div className="col-md-2 mt-5 d-none d-md-block">
                  <div className='1flex'>
                  <h6 className="text-uppercase mb-4 font-weight-bold">
                    Menu
                  </h6>
                  
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/home' className="text-dark text-decoration-none">Home</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/about' className="text-dark text-decoration-none">About</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/calculator' className="text-dark text-decoration-none">Calculator</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/branches' className="text-dark text-decoration-none">Branches</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/partner' className="text-dark text-decoration-none">Partners</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/news' className="text-dark text-decoration-none">News</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/service' className="text-dark text-decoration-none">Services</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/faq' className="text-dark text-decoration-none">FAQs</a>
                    
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/gold-returns-card' className="text-dark text-decoration-none">Gold Returns card</a>
                    
                  </p>
                </div>
                </div>
                <div className='col-md-4 d-block d-md-none' >
                <div className="row">
    <div className="col">
    <h6 className="text-uppercase mb-4 font-weight-bold">
                    Menu
                  </h6>
                  
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/home' className="text-dark text-decoration-none">Home</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/about' className="text-dark text-decoration-none">About</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/calculator' className="text-dark text-decoration-none">Calculator</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/branches' className="text-dark text-decoration-none">Branches</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/partner' className="text-dark text-decoration-none">Partners</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/news' className="text-dark text-decoration-none">News</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/service' className="text-dark text-decoration-none">Services</a>
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/faq' className="text-dark text-decoration-none">FAQs</a>
                    
                  </p>
                  <p className='mb-1 ' style={{fontSize:"12px"}}>
                    <a href='/gold-returns-card' className="text-dark text-decoration-none">Gold Returns card</a>
                    
                  </p>
    </div>
    <div className="col">
    <h6 className="text-uppercase mb-4 font-weight-bold text-center">
                    Socials
                  </h6>
                  <p className='mb-1 text-center ' style={{fontSize:"12px"}}>
                    <a href='https://www.facebook.com/sristargold' className="text-dark text-decoration-none"><i className="fab fa-facebook-f"></i></a>
                  </p>
                  <p className='mb-1 text-center ' style={{fontSize:"12px"}}>
                    <a href='https://www.instagram.com/sristargold/' className="text-dark text-decoration-none"><i className="fab fa-instagram"></i></a>
                  </p>
                  <p className='mb-1 text-center ' style={{fontSize:"12px"}}>
                    <a href='https://in.linkedin.com/company/sristargold' className="text-dark text-decoration-none"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                  </p>
                  <p className='mb-1 text-center ' style={{fontSize:"11px"}}>
                    <a href='https://twitter.com/i/flow/login?redirect_after_login=%2FSriStarGold' className="text-dark text-decoration-none"><i className="fab fa-twitter"></i></a>
                  </p>     
    </div>
  </div>
               </div>

                {/* <hr className="w-100 clearfix d-md-none" /> */}
                <div className="col-md-1 mt-5 d-none d-md-block">
                  <h6 className="text-uppercase mb-4 font-weight-bold text-center">
                    Socials
                  </h6>
                  <p className='mb-1 text-center ' style={{fontSize:"12px"}}>
                    <a href='https://www.facebook.com/sristargold' className="text-dark text-decoration-none"><i className="fab fa-facebook-f"></i></a>
                  </p>
                  <p className='mb-1 text-center ' style={{fontSize:"12px"}}>
                    <a href='https://www.instagram.com/sristargold/' className="text-dark text-decoration-none"><i className="fab fa-instagram"></i></a>
                  </p>
                  <p className='mb-1 text-center ' style={{fontSize:"12px"}}>
                    <a href='https://in.linkedin.com/company/sristargold' className="text-dark text-decoration-none"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                  </p>
                  <p className='mb-1 text-center ' style={{fontSize:"11px"}}>
                    <a href='https://twitter.com/i/flow/login?redirect_after_login=%2FSriStarGold' className="text-dark text-decoration-none"><i className="fab fa-twitter"></i></a>
                  </p>                
                </div>

                {/* <hr className="w-100 clearfix d-md-none" /> */}
                <div className="col-md-4 mx-auto mt-5 d-none d-md-block">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.719080335553!2d77.5712606!3d13.0082814!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae175987bfcea9%3A0x4e8aac7669ecfacf!2sSriStar%20Gold%20Company!5e0!3m2!1sen!2sin!4v1701489759601!5m2!1sen!2sin" style={{border:"0", width:"100%", height:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="col-md-4 mx-auto d-block d-md-none">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.719080335553!2d77.5712606!3d13.0082814!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae175987bfcea9%3A0x4e8aac7669ecfacf!2sSriStar%20Gold%20Company!5e0!3m2!1sen!2sin!4v1701489759601!5m2!1sen!2sin" style={{border:"0", width:"100%", height:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </section>
           
          </div>
        </footer>
      </div>
      </div>
    </div>
  );
}

export default Footer;



import React from 'react'
import Navbars from '../Utility/Navbars'
import Footer from '../Home/Footer'
import Training from '../Images/Icons/training.png'
import Inclusive from '../Images/Icons/INC.png'
import Compt from '../Images/Icons/compt.png'
import Growth from '../Images/Icons/growth.svg'

function Career() {
    return (
        <>
            <Navbars />
            <div className='inner-pages'>
                <section className="about-content career-head">
                    <div className='container'>
                        <div class="shop-by-hero-bg">
                            <div class="blob blob-five blob1 solid-blob"></div>
                            <div class="blob blob-four blob2 solid-blob"></div>
                        </div>
                        <div className='abt-cont'>
                            <h4>Join Our Team at SriStar Gold Company - <span>A Leading Gold Buying Company</span></h4>
                            <p>At SriStar Gold Company, we are committed to providing our clients with the highest level of service and the best value for their gold. As a leading gold buying company, we pride ourselves on our integrity, expertise, and commitment to customer satisfaction. Our team is the backbone of our success, and we are always looking to welcome new members who share our passion for excellence and our dedication to the precious metals industry.</p>

                        </div>
                    </div>
                </section>
                <section className='services-section'>
                    <div className='container'>
                        <div className='services-container careerimg'>
                            <div className='sub-hading'>
                                <h2 className='section-sub-heading'>Why Work with Us</h2>
                            </div>
                            <div className='srv-grid-cont'>
                                <div className='srv-gird-content'>
                                    <div className='src-ico'>
                                        <img className='img-fluid' alt='img-icon-service' src={Growth} />
                                    </div>
                                    <div className='srv-text'>
                                        <h3> Growth Opportunities </h3>
                                        <p>We believe in nurturing talent and providing our employees with the opportunities to grow professionally. Whether you are just starting your career or are an experienced professional, SriStar Gold Company offers a dynamic and supportive environment where you can achieve your career aspirations.</p>
                                    </div>
                                </div>

                                <div className='srv-gird-content'>
                                    <div className='src-ico'>
                                        <img className='img-fluid' alt='img-icon-service' src={Inclusive} />
                                    </div>
                                    <div className='srv-text'>
                                        <h3>Inclusive Culture</h3>
                                        <p>Our team is our most valuable asset, and we strive to create a diverse and inclusive workplace. We celebrate differences and believe that a variety of perspectives leads to better decision-making and a more enjoyable work environment.</p>
                                    </div>
                                </div>
                                <div className='srv-gird-content'>
                                    <div className='src-ico'>
                                        <img className='img-fluid' alt='img-icon-service' src={Training} />
                                    </div>
                                    <div className='srv-text'>
                                        <h3> Training and Development </h3>
                                        <p>At SriStar Gold Company, we are committed to the ongoing development of our staff. We offer comprehensive training programs and professional development opportunities that equip our team with the knowledge and skills to excel in their roles.</p>
                                    </div>
                                </div>

                                <div className='srv-gird-content'>
                                    <div className='src-ico'>
                                        <img className='img-fluid' alt='img-icon-service' src={Compt} />
                                    </div>
                                    <div className='srv-text'>
                                        <h3>Competitive Compensation </h3>
                                        <p>We offer competitive salaries, performance bonuses, and a comprehensive benefits package that includes health insurance, retirement plans, and paid time off. </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className='mid-section'>
                    <div className='container'>
                        <div className='how-to-apply'>
                            <div className='sub-hading'>
                                <h2 className='section-sub-heading'>How to <span>Apply</span></h2>
                            </div>
                            <p>If you're interested in pursuing a career with SriStar Gold Company, we would love to hear from you. Please visit our website at www.stargoldcompany.com to view detailed job descriptions and submit your application online. Include your resume, cover letter, and any relevant certifications or portfolios.</p>
                            <p>Join us at SriStar Gold Company and be a part of a leading gold buying company that values integrity, excellence, and a commitment to our clients. We look forward to welcoming you to our team!</p>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Career
import React from 'react'
import { useState } from 'react';

function Dropdown1() {
  const [expandedSections, setExpandedSections] = useState({
    section1: false,
    section2: false,
    section3: false,
    section4: false,
    section5: false,
    section6: false,
    section7: false,
    section8: false,
    section9: false,
    section10: false,
    section11: false,
    section12: false,
    section13: false,
    section14: false,
    section15: false,
    section16: false,
    section17: false,
    section18: false,
    section19: false,
    section20: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div>
      <div onClick={() => toggleSection('section1')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.1 Why Sell my metals to Sristar Gold Company?</div>
      <div className={expandedSections.section1 ? 'expanded' : 'collapsed'}>
        <p className='ml-4 pt-3'>We offer the highest prices for your metals. Our operations are conducted in a professional, transparent and ethical manner. We pay instantly.</p>
      </div>
      <p onClick={() => toggleSection('section2')} className=' pb-4 pt-3' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.2 How long does the whole process take? </p>
      <div className={expandedSections.section2 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We take around 15 minutes to verify your metals and documents. After verification, we buy your metals and pay you immediately.</p>
      </div>
      <p onClick={() => toggleSection('section3')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.3 How do you check the metals? </p>
      <div className={expandedSections.section3 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We check the hallmark on the metals and ownership proofs. We check the purity of gold through XRF technology called Karat Meter.</p>
      </div>
      <p onClick={() => toggleSection('section4')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.4 What is the charge for testing and purity evaluation? </p>
      <div className={expandedSections.section4 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We offer free testing and purity evaluation</p>
      </div>
      <p onClick={() => toggleSection('section5')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.5 Will my metals get damaged during testing? </p>
      <div className={expandedSections.section5 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'> No, your metals won’t be damaged while the computerised testing. </p>
      </div>
      <p onClick={() => toggleSection('section6')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.6 What kind of metals do you buy? </p>
      <div className={expandedSections.section6 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We buy all types of precious metal such as Platinum, gold, diamond & silver.</p>
      </div>
      <p onClick={() => toggleSection('section7')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.7  How do you decide the price of my metals? </p>
      <div className={expandedSections.section7 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We use a simple formula. Total Grams (Net Weight) x Purity Percentage x Today’s rate = Price of your metal. We don’t take the weight of fake stones/enamels/dust/wax into consideration.</p>
        <p className='ml-4' style={{color:'red'}}>** We compensate for the values of real stones (Ruby/Emerald/Diamonds etc).</p>
      </div>
      <p onClick={() => toggleSection('section8')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.8 Do you pay for valuable stones in the metals? </p>
      <div className={expandedSections.section8 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. We pay compensation for the values of precious stones. </p>
      </div>
      <p onClick={() => toggleSection('section9')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.9 How will you pay me for my metals? </p>
      <div className={expandedSections.section9 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>We make all payments following a strict guideline via Cash/NEFT/IMPS/RTGs</p>
      </div>
      <p onClick={() => toggleSection('section10')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.10  Can I buy back my metals after selling? </p>
      <div className={expandedSections.section10 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Once you sell us your metals you cannot get it pack.</p>
      </div>
      <p onClick={() => toggleSection('section11')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.11  Is there any minimum or maximum quantity for selling my metals? </p>
      <div className={expandedSections.section11 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>No. We accept any quantity you offer us.</p>
      </div>
      <p onClick={() => toggleSection('section12')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.12 Do you keep margins? </p>
      <div className={expandedSections.section12 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. We do keep a minimal margin to keep our business running.</p>
      </div>
      <p onClick={() => toggleSection('section13')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.13  Can I sell a relative’s or a friend’s metals? </p>
      <div className={expandedSections.section13 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>No. You can only sell metals that you own. You can also sell metals belonging to your spouse or parents, but only with their consent.</p>
      </div>
      <p onClick={() => toggleSection('section14')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.14 On what grounds will you reject my metals? </p>
      <div className={expandedSections.section14 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'> We can reject your metals on the following grounds.</p>
        <ul>
          <li>Incorrect KYC</li>
          <li>Issues with ownership proof</li>
          <li>Problem with verifying the metal purity</li>
          <li>If you’re under 21 years</li>
          <li>Selling on behalf of a relative or a friend</li>
          <li>If denying for house verification</li>
        </ul>
      </div>
      <p onClick={() => toggleSection('section15')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.15 Will my data be safe? </p>
      <div className={expandedSections.section15 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. We store your data confidentially. It won’t be disclosed or misused. However, if the government authorities demand your data, we will have to comply.</p>
      </div>
      <p onClick={() => toggleSection('section16')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.16 Is there any eligibility for selling my metals? </p>
      <div className={expandedSections.section16 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. You must be at least 21+ years or above to sell your metals. Yes, You should have completed 21 years of age on the date of selling gold.</p>
      </div>
      <p onClick={() => toggleSection('section17')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.17 Are you open on Sundays? </p>
      <div className={expandedSections.section17 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'>Yes. We are open Every Day. Even on Sundays</p>
      </div>
      <p onClick={() => toggleSection('section18')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.18 What documents will I need to sell my metals? </p>
      <div className={expandedSections.section18 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'> You will need the following documents to sell your metals </p>
        <ul>
          <li>ID Proof (PAN Card/Passport/Ration Card/Driving Licence)</li>
          <li>Address Proof (Adhar Card/Driving Licence/Voter ID/Gas Bill/Electricity Bill/Bank Passbook/BSNL Telephone Bill)</li>
          <li>Ownership Proof</li>
        </ul>
      </div>
      <p onClick={() => toggleSection('section18')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}> Q.19 Will I need a local address proof? </p>
      <div className={expandedSections.section18 ? 'expanded' : 'collapsed'}>
        <p className='ml-4'> Yes. We need a copy of your local address proof. However, if you’re not a resident of Karnataka, a copy of your native address proof will work. </p>
      </div>
    </div>
  )
}

export default Dropdown1
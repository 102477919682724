import './App.css';
import Router from './Utility/Router';

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;

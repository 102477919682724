import React from 'react'
import cardImg from "../Images/card.jpg"
import Slider from './Slider'
import backgroundReturn from '../Images/bag-apply.png'

function ApplyNow() {
    return (
        <>
            <section className='gold-return-section' style={{ backgroundImage: `url(${backgroundReturn})` }}>
                <div className='container'>
                    <div className='gold-return-section'>
                        <div className="row">
                            <div className="col-sm-6 col-md-5 col-lg-6" >
                                <div className='gld-return-img'>
                                    <img src={cardImg} alt='card' className='image-fluid ' />
                                </div>
                            </div>
                            {/* <div className="col-sm-6 col-md-5 col-lg-6 d-block d-md-none " >
                            <img src={cardImg} alt='card' className='image-fluid' />
                        </div> */}
                            <div className="col-sm-6 col-md-5 col-lg-6" >
                                <div className='gold-return-content'>
                                    <h3>INTRODUCING  THE  NEW GOLD  RETURNS  CARD</h3>
                                    <div>
                                        <p>We are thrilled to introduce the enhanced Gold Returns Card, an exclusive privilege card offered exclusively by SriStar Gold.</p>
                                        <p>This card offers a 4-year validity period and an array of benefits that will elevate your gold ownership experience to unprecedented heights,</p>

                                        {/* <div className='d-none d-md-block'>
                                        <a href='mailto: sristargoldcompany@gmail.com'>
                                            <button type="button" className="btn w-auto">Apply Now</button></a>
                                    </div> */}
                                        <div className='gold-return-btn'>
                                            <a href='mailto: sristargoldcompany@gmail.com'>Apply Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='slider-section'>
                <Slider />
            </section>
        </>
    )
}

export default ApplyNow
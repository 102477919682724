import React from 'react'
import Navbars from '../Utility/Navbars'
import BranchesPage from '../Branch/BranchesPage'
// import Branch from '../Branch/Branch'
import Footer from '../Home/Footer'

function Branches() {
  return (
    // <div>
    //   <div className='bg-branch'>
    //     <Navbars />
    //     <BranchesPage />
    //     {/* <Branch /> */}
    //   </div>
    //   <Footer />
    // </div>
    <>
      <Navbars />

      <div className='inner-pages branches-bg'>

        <BranchesPage />

      </div>
      <Footer />
    </>
  )
}

export default Branches
import React from 'react'
import "../css/style.css"
import middle from "../Images/middle.jpg"
import bangle from "../Images/img2.jpg"
import necklace from "../Images/necklace.jpg"
import swal from 'sweetalert';
import { useState } from 'react';
import GoldHigh from '../Images/Icons/gold-high.svg'
import GoldIco from '../Images/Icons/gold.svg'
import Rent from '../Images/Icons/renrt.svg'
import WeBuy from '../Images/Icons/we-buy.svg'



function Services() {
    const [spinner, setSpinner] = useState(false);
    // const navigate = useNavigate();
    const [formData, setFormData] = useState({
        full_name: '',
        phone_number: '',
        service_required: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSpinner(true);
        const result = formData;
        console.log(result)

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(formData),
        };

        try {
            // const response = await fetch(`https://backend.aslikahani.com/purity/v1/leads/submit`, requestOptions);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/leads/contactus/submit`, requestOptions);
            setSpinner(false);
            if (response.ok) {
                swal({
                    title: "Query Submitted",
                    text: "Team will get back to you shortly",
                    icon: "success"
                })

                console.log('Form data submitted successfully!', response);
            }
            else {
                swal({
                    title: "Failed!!!",
                    text: "Please check your details and try again",
                    icon: "error"
                })
            }
        } catch (error) {

            console.error('Error submitting form data:', error);
        }
    };
    return (
        <>
            <section className='mid-section'>
                <div className='mid-form-container'>
                    <div className='container'>
                        <div className='sub-hading'>
                            <h2>Get<strong> Highest</strong>  Price of Gold Call Now to Get Special Offer.</h2>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-12'>
                                <div className="left-click-content">
                                    {/* <div className="get-price">
                                        <h2>Get<strong> Highest</strong>  Price of Gold Call Now to Get Special Offer.</h2>
                                        <h5 >We are the only Gold Buyer having Govt of India Certified Gold Appraiser.</h5>
                                        <div className='scl-lft-grid'>
                                            <div className='social-anc'>
                                                <a href="tel:6366333444" className=''>
                                                    <button type="submit" className="btn btn-outline-dark">
                                                        Call Now
                                                    </button>
                                                </a>
                                            </div>
                                            <div className='social-anc'>
                                                <a href="https://wa.me/6366333444" >
                                                    <button type="submit" className="btn btn-outline-dark">
                                                        Whatsapp Us</button></a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="lft-grid-img">
                                        <img src={necklace} className='image-fluid ' />
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 col-12'>
                                <div className='form-section'>
                                    <div className="get-price">

                                        <h5 >We are the only Gold Buyer having Govt of India Certified Gold Appraiser.</h5>
                                        <div className='scl-lft-grid'>
                                            <div className='social-anc'>
                                                <a href="tel:6366333444" className=''>
                                                    <button type="submit" className="btn btn-outline-dark">
                                                        Call Now
                                                    </button>
                                                </a>
                                            </div>
                                            <div className='social-anc'>
                                                <a href="https://wa.me/6366333444" >
                                                    <button type="submit" className="btn btn-outline-dark">
                                                        Whatsapp Us</button></a>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-container'>
                                            <div className="form-input">
                                                <label className='' for="name" >Your Name  </label>
                                                <input type="text" className="" id="name"
                                                    name='full_name'
                                                    value={formData.full_name}
                                                    onChange={handleChange}
                                                    required />
                                            </div>
                                            <div className="form-input">
                                                <label className=' ' for="number">Phone Number</label>
                                                <input type="number" className="" id="phone"
                                                    name='phone_number'
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    required />
                                            </div>
                                            <div className="form-input">
                                                <label className='' htmlFor="options">Select Services</label>
                                                <select className="" id="services" name='service_required'
                                                    // value={formData.service_required}
                                                    onChange={handleChange}
                                                    required>
                                                    <br />
                                                    <option selected disabled>Choose here</option>
                                                    <option value="Sell gold">Sell Gold</option>
                                                    <option value="Release Pledged Gold">Release Pledged Gold</option>
                                                    <option value="RePledge Your Gold">Re-Pledge Your Gold</option>
                                                    <option value="24 Rent Gold">Rent Gold</option>
                                                </select>
                                            </div>
                                            <div className='form-submit'>
                                                <button type="submit" className=" "
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='services-section'>
                <div className='container'>
                    <div className='services-container'>
                        <div className='sub-hading'>
                            <h2 className='section-sub-heading'>Services</h2>
                        </div>
                        <div className='srv-grid-cont'>
                            <div className='srv-gird-content'>
                                <div className='src-ico'>
                                    <img className='img-fluid' alt='img-icon-service' src={WeBuy} />
                                </div>
                                <div className='srv-text'>
                                    <h3> We Buy Gold </h3>
                                    <p>Do you want to sell your gold or other Precious Metals? But, you don’t know whom to trust or where to get the best deal? We are your golden solution.  It is our goal to offer you the best price. We buy your gold along with other valuable metals, new or old, at the current market rates.</p>
                                </div>
                            </div>
                            {/* <div className="serv-grd-img" >
                                <img src={middle} alt='middle-img' className='img-fluid' />

                                <img src={middle} alt='middle-img' className='image-responsive' style={{ width: "100%", height: "220px" }} />
                            </div> */}
                            <div className='srv-gird-content'>
                                <div className='src-ico'>
                                    <img className='img-fluid' alt='img-icon-service' src={GoldIco} />
                                </div>
                                <div className='srv-text'>
                                    <h3>We Sell Gold</h3>
                                    <p>Do you want vintage or latest gold jewellery at lower than market rates?  Then your quest is over! We bring to you our broad range of vintage, old and new gold jewellery.</p>
                                </div>
                            </div>
                            <div className='srv-gird-content'>
                                <div className='src-ico'>
                                    <img className='img-fluid' alt='img-icon-service' src={GoldHigh} />
                                </div>
                                <div className='srv-text'>
                                    <h3> We Release Pledged Gold </h3>
                                    <p>Are you worried about paying monthly interests for your pledged gold? Not anymore! We are here to your rescue.  Yes, we release your pledged gold from banks, NBCFs, pawn shops as well as financer. So, you receive the best value for your gold instead of losing it all.</p>
                                </div>
                            </div>
                            {/* <div className="serv-grd-img">
                                <img src={bangle} alt='middle-img' className='image-responsive' />
                            </div> */}
                            <div className='srv-gird-content'>
                                <div className='src-ico'>
                                    <img className='img-fluid' alt='img-icon-service' src={Rent} />
                                </div>
                                <div className='srv-text'>
                                    <h3>We Rent Gold </h3>
                                    <p>Are you tired of wearing the same jewellery to every wedding or festival?  For the very first time, we bring to you gold jewellery on rent. You can rent for a day or longer. </p>
                                </div>
                            </div>
                            <div className='srv-gird-button'>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M17 15.586 6.707 5.293 5.293 6.707 15.586 17H7v2h12V7h-2v8.586z" /></svg> */}
                                {/* <h3>Explore Our Other Services</h3><br /> */}

                                <a href='/services' style={{ textDecoration: "none" }}>
                                    Explore Our Other Services
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Services
import React from 'react'
import Navbars from '../Utility/Navbars'
import Footer from '../Home/Footer'
import NewsSlider from '../News/NewsSlider'

function News() {
  return (
    <>
      <Navbars />
      <div className='inner-pages'>
        <div class="sub-hading"><h2>NEWS & PROMOTIONS</h2></div>
      </div>
      <NewsSlider />
      <Footer />
    </>
  )
}

export default News
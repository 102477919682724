import React from 'react'
import Navbars from '../Utility/Navbars'
import ServicesPage from '../Service/ServicesPage'
import Footer from '../Home/Footer'

function Services() {
  return (
    <>
      <Navbars />
      <div className='inner-pages'>
        <div class="sub-hading"><h2>Services</h2></div>
        <ServicesPage />
      </div>
      <Footer />
    </>
  )
}

export default Services
import React from 'react'
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

function Form() {

    const [spinner, setSpinner] = useState(false);
    // const navigate = useNavigate();
    const [formData, setFormData] = useState({
        full_name: '',
        city: '',
        gold_grams: '',
        karat_score: '',
        phone_number: '',
        email: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSpinner(true);
        const result = formData;
        console.log(result)

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(formData),
        };

        try {
            // const response = await fetch(`https://backend.aslikahani.com/purity/v1/leads/submit`, requestOptions);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/leads/gold/submit`, requestOptions);
            setSpinner(false);
            if (response.ok) {
                swal({
                    title: "Query Submitted",
                    text: "Team will get back to you shortly",
                    icon: "success"
                })

                console.log('Form data submitted successfully!', response);
            }
            else {
                swal({
                    title: "Failed!!!",
                    text: "Please check your details and try again",
                    icon: "error"
                })
            }
        } catch (error) {

            console.error('Error submitting form data:', error);
        }
    };


    return (
        // <div>
        //     <div className='col-md-12'>
        //         <div className='container'>
        //             <div className='row'>
        //                 <div className='col-md-3 form-calculate'>
        //                     <h1>Enter  The
        //                         Following
        //                         Details  To
        //                         Get  Instant
        //                         Rates</h1>
        //                 </div>
        //                 <div className='col-md-9'>
        //                     <form onSubmit={handleSubmit}>
        //                         <div className="form-group">
        //                             <label className='label' for="name" >Full Name <span>*</span> </label>
        //                             <input type="text" className="form-control input" id="full_name" name='full_name'
        //                             value={formData.full_name}
        //                             onChange={handleChange}
        //                             required/>
        //                         </div>
        //                         <div className='row'>
        //                         <div className="col-md-6 form-group">
        //                             <label className='label' for="phone">Phone <span>*</span></label>
        //                             <input type="tel" className="form-control input" id="phone_number" name='phone_number'
        //                              value={formData.phone_number}
        //                              onChange={handleChange}
        //                             required />
        //                         </div>
        //                         <div className="col-md-6 form-group">
        //                             <label className='label' for="email">Email <span>*</span></label>
        //                             <input type="email" className="form-control input" id="email" name='email'
        //                              value={formData.email}
        //                              onChange={handleChange}
        //                             required/>
        //                         </div>
        //                         {/* <div className="col-md-6 form-group">
        //                             <label className='label' for="city">OTP <span>*</span></label>
        //                             <input type="text" className="form-control input" id="city"  required/>
        //                         </div> */}
        //                         <div className="col-md-6 form-group">
        //                             <label className='label' for="city">City <span>*</span></label>
        //                             <input type="text" className="form-control input" id="city" name='city'
        //                              value={formData.city}
        //                              onChange={handleChange}
        //                             required/>
        //                         </div>
        //                         <div className="col-md-6 form-group">
        //                             <label className='label' for="city">Gold Weight (in grams) <span>*</span></label>
        //                             <input type="text" className="form-control input" id="gold_grams" name='gold_grams'
        //                              value={formData.gold_grams}
        //                              onChange={handleChange}
        //                             required />
        //                         </div>
        //                         <div className="col-md-6 form-group mt-3">
        //                                 <label className='label' htmlFor="options">Gold Carat <span>*</span></label>
        //                                 <select className="form-control input" id="karat_score" name='karat_score'
        //                                  value={formData.karat_score}
        //                                  onChange={handleChange}
        //                                 required>
        //                                     {/* <option value=""></option> */}
        //                                     <option value="18">18k</option>
        //                                     <option value="20">20k</option>
        //                                     <option value="22">22k</option>
        //                                     <option value="24">24k</option>
        //                                     <option value="0">Other</option>
        //                                 </select>
        //                             </div>
        //                             <div className='col-md-7 form-btn mt-4'>
        //                         <button type="submit" className="btn btn-primary"
        //                         onClick={handleSubmit}
        //                         >Get Price</button>
        //                        </div>
        //                         </div>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className='form-calc-cont'>
            <div className='calc-heading'>
                <h4>Enter The Following Details To Get Instant Rates
                </h4>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='row v-gap-15 '>
                    <div className='col-md-6 col-12'>
                        <div className="form-input">
                            <label for="name" >Full Name <sup>*</sup> </label>
                            <input type="text" id="full_name" name='full_name'
                                value={formData.full_name}
                                onChange={handleChange}
                                required />
                        </div>
                    </div>

                    <div className='col-md-6 col-12'>
                        <div className="form-input">
                            <label for="phone">Phone <sup>*</sup></label>
                            <input type="tel" id="phone_number" name='phone_number'
                                value={formData.phone_number}
                                onChange={handleChange}
                                required />
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className="form-input">
                            <label for="email">Email <sup>*</sup></label>
                            <input type="email" id="email" name='email'
                                value={formData.email}
                                onChange={handleChange}
                                required />
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className="form-input">
                            <label for="city">City <sup>*</sup></label>
                            <input type="text" id="city" name='city'
                                value={formData.city}
                                onChange={handleChange}
                                required />
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className="form-input">
                            <label for="city">Gold Weight (in grams) <sup>*</sup></label>
                            <input type="text" id="gold_grams" name='gold_grams'
                                value={formData.gold_grams}
                                onChange={handleChange}
                                required />
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className="form-input">
                            <label htmlFor="options">Gold Carat <sup>*</sup></label>
                            <select className="form-control input" id="karat_score" name='karat_score'
                                // value={formData.karat_score}
                                onChange={handleChange}
                                required>
                                <option selected disabled>Choose here</option>
                                <option value="18">18k</option>
                                <option value="20">20k</option>
                                <option value="22">22k</option>
                                <option value="24">24k</option>
                                <option value="0">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className='form-submit'>
                        <button type="submit"
                            onClick={handleSubmit}
                        >Get Price</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default Form
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useEffect } from "react";
import "./navbar.css";
import bg from "../Images/instant-cash-for-gold.jpg"
import ScrollDownButton from "./ScrollDownButton";
import logo from "../Images/HomeLogo.png"
import Topbar from "./Topbar";

// import Search from "./Search";
function Navbars() {
    const [click, setClick] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => setClick(!click);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const [navbarColor, setNavbarColor] = useState('transparent');
    const [isSticky, setIsSticky] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 10) {
            setNavbarColor('#441e39');
            setIsSticky(true);
        } else {
            setNavbarColor('transparent');
            setIsSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNavbarColor);
        return () => {
            window.removeEventListener('scroll', changeNavbarColor);
        };
    }, []);

    return (
        <>
            {/* <nav className={`nav-bar ${isSticky ? 'sticky' : ''}`} style={{ backgroundColor: navbarColor }}> */}
            <nav className={`nav-bar wow bounceInUp ${isSticky ? 'sticky' : ''}`}>
                <div className="container">
                    <div className="navbars">
                        <Topbar />
                        <div className="nav-container">
                            <NavLink to="/" className="nav-logo">
                                <img src={logo} alt="logo" className="img-fluid" />
                            </NavLink>
                            <ul className={click ? "nav-menu active" : "nav-menu"}>
                                <li className="nav-item">
                                    <NavLink
                                        to="/home"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/about"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        About
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/calculator"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        Calculator
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/branches"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        Branches
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/partner"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        Partners
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/news"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        News
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/services"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        Services
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/faq"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        FAQs
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/gold-returns-card"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        GoldReturnsCard
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        // exact
                                        to="/career"
                                        className="nav-links text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        Career
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <ScrollDownButton />
                                </li> */}

                                {/* <ScrollDownButton /> */}
                            </ul>

                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
 <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
</svg> */}
                            <div className="nav-icon" onClick={handleClick}>
                                <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbars;
import React, { useState } from 'react';
import Dropdown1 from '../FAQ/Dropdown1';
import Dropdown2 from '../FAQ/Dropdown2';
import Dropdown3 from '../FAQ/Dropdown3';
import Dropdown4 from '../FAQ/Dropdown4';

function Questions() {
  const [isButton1Active, setIsButton1Active] = useState(true);
  const [isButton2Active, setIsButton2Active] = useState(false);
  const [isButton3Active, setIsButton3Active] = useState(true);
  const [isButton4Active, setIsButton4Active] = useState(false);
  const [isButton5Active, setIsButton5Active] = useState(false);
  const [isButton6Active, setIsButton6Active] = useState(false);

  const activateButton1 = () => {
    setIsButton1Active(true);
    setIsButton2Active(false);
    setIsButton3Active(false);
    setIsButton4Active(false);
    setIsButton5Active(false);
    setIsButton6Active(false);
  };

  const activateButton2 = () => {
    setIsButton1Active(false);
    setIsButton2Active(true);
    setIsButton3Active(false);
    setIsButton4Active(false);
    setIsButton5Active(false);
    setIsButton6Active(false);
  };

  const activateButton3 = () => {
    setIsButton1Active(false);
    setIsButton2Active(false);
    setIsButton3Active(true);
    setIsButton4Active(false);
    setIsButton5Active(false);
    setIsButton6Active(false);
  };

  const activateButton4 = () => {
    setIsButton1Active(false);
    setIsButton2Active(false);
    setIsButton3Active(false);
    setIsButton4Active(true);
    setIsButton5Active(false);
    setIsButton6Active(false);
  };

  const activateButton5 = () => {
    setIsButton1Active(false);
    setIsButton2Active(false);
    setIsButton3Active(false);
    setIsButton4Active(false);
    setIsButton5Active(true);
    setIsButton6Active(false);
  };

  const activateButton6 = () => {
    setIsButton1Active(false);
    setIsButton2Active(false);
    setIsButton3Active(false);
    setIsButton4Active(false);
    setIsButton5Active(false);
    setIsButton6Active(true);
  };

  return (
    <div>
      
      <div className='container'>
        <br />
        <br />
        <div className='d-none d-md-block'>
          <div className='row m-auto'>
            {/* <div className='col-md-3 m-auto text-center faq'>
              <button onClick={activateButton1} className={isButton1Active ? 'active' : ''} style={{ width: "-webkit-fill-available", height: "130px" }}>
                Documents Accepted
              </button>
            </div> */}
            {/* <div className='col-md-3 m-auto text-center faq'>
              <button onClick={activateButton2} className={isButton2Active ? 'active' : ''} style={{ width: "-webkit-fill-available", height: "130px" }}>
                Eligibility
              </button>
            </div> */}
            <div className='col-md-3 m-auto text-center faq'>
              <button onClick={activateButton3} className={isButton3Active ? 'active' : ''} style={{ width: "-webkit-fill-available", height: "130px" }}>
                We Buy Gold
                {/* Selling Gold To Sristar Gold Company */}
              </button>
            </div>
            <div className='col-md-3 m-auto text-center faq'>
              <button onClick={activateButton4} className={isButton4Active ? 'active' : ''} style={{ width: "-webkit-fill-available", height: "130px" }}>
                We Give Gold On Rent
                {/* Renting Gold From Sristar Gold Company */}
              </button>
            </div>
            <div className='col-md-3 m-auto text-center faq'>
              <button onClick={activateButton5} className={isButton5Active ? 'active' : ''} style={{ width: "-webkit-fill-available", height: "130px" }}>
                We Release Your Pledge Gold
                {/* We releasing Your Pledged Gold */}
              </button>
            </div>
            <div className='col-md-3 m-auto text-center faq'>
              <button onClick={activateButton6} className={isButton6Active ? 'active' : ''} style={{ width: "-webkit-fill-available", height: "130px" }}>
                We Sell Gold
                {/* Purchasing Gold From Sristar Gold Company */}
              </button>
            </div>
          </div>
        </div>


        <div className='d-block d-md-none'>
          <div className='row'>
            {/* <div className='col-4 faq mb-2' style={{ padding: "inherit", borderRight: "5px solid #e3e3e3" }}>
              <button onClick={activateButton1} className={isButton1Active ? 'active' : ''} style={{ width: "100%", height: "100px", fontSize: "smaller" }}>
                Documents<br /> Accepted
              </button>
            </div> */}
            {/* <div className='col-4 faq mb-2' style={{ padding: "inherit", borderRight: "5px solid #e3e3e3" }}>
              <button onClick={activateButton2} className={isButton2Active ? 'active' : ''} style={{ width: "100%", height: "100px" }}>
                Eligibility
              </button>
            </div> */}
            <div className='col-4 faq mb-2 col-12' style={{ padding: "inherit"}}>
              <button onClick={activateButton3} className={isButton3Active ? 'active' : ''} style={{ width: "90%",margin:'auto', height: "100px" }}>
                We Buy Gold
                {/* Selling Gold To Sristar Gold Company */}
              </button>
            </div>
            <div className='col-4 faq mb-2 col-12' style={{ padding: "inherit"}} >
              <button onClick={activateButton4} className={isButton4Active ? 'active' : ''} style={{ width: "90%",margin:'auto', height: "100px" }}>
                We Give Gold On Rent
                {/* Renting Gold From Sristar Gold Company */}
              </button>
            </div>
            <div className='col-4 faq mb-2 col-12' style={{ padding: "inherit"}}>
              <button onClick={activateButton5} className={isButton5Active ? 'active' : ''} style={{ width: "90%",margin:'auto', height: "100px" }}>
                We Release Your Pledge Gold
                {/* We releasing Your Pledged Gold */}
              </button>
            </div>
            <div className='col-4 faq mb-2 col-12' style={{ padding: "inherit"}}>
              <button onClick={activateButton6} className={isButton6Active ? 'active' : ''} style={{ width: "90%",margin:'auto', height: "100px" }}>
                We Sell Gold
                {/* Purchasing Gold From Sristar Gold Company */}
              </button>
            </div>
          </div>
        </div>


        <div>
          <br />
          <br />
          {/* <br/> */}
          {/* {isButton1Active && <p>
            <p className='p-2' style={{ border: "1px solid black" }}>
              ID Proof: PAN Card/Passport/Ration Card/Driving Licence
            </p>
            <p className='p-2' style={{ border: "1px solid black" }}>
              Address proof: Adhar Card/Driving Licence/Voter ID/Gas Bill/Electricity Bill/Bank Passbook/BSNL Telephone Bill
            </p>
            <p className='text-danger font-weight-bold' style={{ fontSize: "smaller" }}>** In case of NRI/PIO: Passport, PAN Card, Current Indian Address Proof
              <br /><span className=''>** In case of Non-Indian/Immigrants: Passport, Visa, Current Indian Address Proof </span><br /><span>** Selling Stolen gold is offense, if caught reported to
                authorities</span></p>


          </p>
          } */}

          {/* {isButton2Active && <p>
            <p className='p-2' style={{ border: "1px solid black" }}>
              Anybody who is 21+ years is eligible to sell their precious metal to us. Anyone below the age of 21 requires written consent from their parents or legal guardian.
            </p>
            <p className='p-2' style={{ border: "1px solid black" }}>
              You have to provide us with 1 ID Proof and 1 Address Proof (for verification purpose).
              <br />
              <span style={{ color: 'red' }}>** Permanent Address Proof is required for non-localities.</span>
              <br />
              <span style={{ color: 'red' }}>** Passport and Visa are mandatory for Non-Indians/Immigrants.</span>
            </p>
            <p className='p-2' style={{ border: "1px solid black" }}>
              You have to carry the Original Purchase Bill/Receipt. You can sell metals that you have inherited, or belonging to your spouse, only with their written consent.
            </p>
            <p className='p-2' style={{ border: "1px solid black" }}>
              You cannot sell metals belonging to a relative or friend or anyone else
            </p>
            <p className='text-danger font-weight-bold' style={{ fontSize: "smaller" }}>** In case of NRI/PIO: Passport, PAN Card, Current Indian Address Proof
              <br /><span className=''>** In case of Non-Indian/Immigrants: Passport, Visa, Current Indian Address Proof</span></p>
            <p className='text-danger font-weight-bold' style={{ fontSize: "smaller" }}>Selling Stolen gold is offense, if caught reported to
              authorities</p>

          </p>} */}


          {isButton3Active && <p className='questions'>
            <Dropdown1 />
          </p>}
          {isButton4Active && <p>
            <Dropdown2 />
          </p>}
          {isButton5Active && <p>
            <Dropdown3 />
          </p>}
          {isButton6Active && <p>
            <Dropdown4 />
          </p>}
        </div>

      </div> <br /><br />
      <section className='history-section'>
        <div className='container'>
          <div className='doc-data'>
            <div className='doc-list-on'>
              <h4>Documents Accepted</h4>
              <div className='doc-list-data'>
                <p>ID Proof: PAN Card/Passport/Ration Card/Driving Licence</p>
                <p>Address proof: Adhar Card/Driving Licence/Voter ID/Gas Bill/Electricity Bill/Bank Passbook/BSNL Telephone Bill</p>
                <p><span>** In case of NRI/PIO: Passport, PAN Card, Current Indian Address Proof</span></p>
                <p><span>** In case of Non-Indian/Immigrants: Passport, Visa, Current Indian Address Proof</span></p>
                <p><span>** Selling Stolen gold is offense, if caught reported to authorities</span></p>
              </div>
            </div>
            <div className='doc-list-on'>
              <h4>Eligibility</h4>
              <div className='doc-list-data'>
                <p>Anybody who is 21+ years is eligible to sell their precious metal to us. Anyone below the age of 21 requires written consent from their parents or legal guardian.</p>
                <p>You have to provide us with 1 ID Proof and 1 Address Proof (for verification purpose).</p>
                <p><span>** Permanent Address Proof is required for non-localities.</span></p>
                <p><span>** Passport and Visa are mandatory for Non-Indians/Immigrants.</span></p>
                <p>You have to carry the Original Purchase Bill/Receipt. You can sell metals that you have inherited, or belonging to your spouse, only with their written consent.</p>
                <p>You cannot sell metals belonging to a relative or friend or anyone else</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Questions;

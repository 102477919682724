import React from 'react'
import { useState } from 'react'

function Dropdown3() {

    const [expandedSections, setExpandedSections] = useState({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: false,
        section6: false,
        section7: false,
        section8: false,
        section9: false,
        section10: false,
        section11: false,
        section12: false,
    });

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };
    return (
        <div>

            <div onClick={() => toggleSection('section1')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.1: Do you release gold that has been pledged already?</div>
            <div className={expandedSections.section1 ? 'expanded' : 'collapsed'}>
                <p className='ml-4 pt-3'>Yes. We help to release gold from Banks, NBFCs, Pawn Shops, and Financiers.</p></div>

            <p onClick={() => toggleSection('section2')} className=' pb-4 pt-3' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.2: What is the process of releasing the pledged gold?
            </p>
            <div className={expandedSections.section2 ? 'expanded' : 'collapsed'}>
                <p className='ml-4'>
                    <b>Visit the nearest Sristar Gold Company to you with:</b></p>
                <ul>
                    <li>Original receipt of your pledged gold</li>
                    <li>An ID Proof (PAN Card/Passport/Ration Card/Driving Licence)</li>
                    <li>An Address Proof (Adhar Card/Driving Licence/Voter ID/Gas Bill/Electricity Bill/Bank Passbook/BSNL Telephone Bill)</li>
                    <li>Original Purchase Bill</li>
                    <li>One copy of your photo (Passport sized).</li>
                </ul>
                <p className='ml-4'>We will take care of everything else.</p>

            </div>

            <div onClick={() => toggleSection('section3')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.3: Do you release gold from more than one location?</div>
            <div className={expandedSections.section3 ? 'expanded' : 'collapsed'}>
                <p className='ml-4 pt-3'>Yes. We release gold from more than one location. Please visit or contact us for further information.</p></div>

            <p onClick={() => toggleSection('section4')} className=' pb-4 pt-3' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.4: Do you have a minimum or maximum quantity for releasing gold?
            </p>
            <div className={expandedSections.section4 ? 'expanded' : 'collapsed'}>
                <p className='ml-4'>No. We release all quantities of gold.</p>
            </div>

            <div onClick={() => toggleSection('section5')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.5: How long does the entire process take?</div>
            <div className={expandedSections.section5 ? 'expanded' : 'collapsed'}>
                <p className='ml-4 pt-3'>After releasing the gold, we bring it back to our office for purity evaluation. The final settlement is made once the testing is done.</p></div>

            <p onClick={() => toggleSection('section6')} className=' pb-4 pt-3' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.6:  Can I keep some of my gold after the release?
            </p>
            <div className={expandedSections.section6 ? 'expanded' : 'collapsed'}>
                <p className='ml-4'>Yes. You can keep some of your released gold. However, you will need to inform us in advance.</p>
            </div>

            <p onClick={() => toggleSection('section7')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.7:  What if I am not happy with the price offered for my pledged gold?
            </p>
            <div className={expandedSections.section7 ? 'expanded' : 'collapsed'}>
                <p className='ml-4'> Based on the details you provide us, we give you an estimation prior to releasing your gold. However, if you’re still not satisfied with the price we offer, you must refund us the entire amount we paid to release your gold + Up to 2% to 4% service charges.</p>
            </div>
            <p onClick={() => toggleSection('section8')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.8: Do you charge for the service of releasing pledged gold?
            </p>
            <div className={expandedSections.section8 ? 'expanded' : 'collapsed'}>
                <p className='ml-4'> No. The service is free of cost. However, if you decide not to sell your gold to us after the release, you will need to pay us.</p>
            </div>
            <p onClick={() => toggleSection('section9')} className=' pb-4' style={{ borderBottom: "1px dotted black", cursor: "pointer" }}>
                Q.9: Do I have to be present when my gold is released from Banks, NBFCs, Pawn Shops, or Financiers?
            </p>
            <div className={expandedSections.section9 ? 'expanded' : 'collapsed'}>
                <p className='ml-4'>Yes. You have to be present during the release of your pledged gold, accompanied by one of our executives. </p>
            </div>


        </div>
    )
}

export default Dropdown3
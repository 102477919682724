import React from 'react'
import Vision from './Vision'

function OurVision() {
    return (
        //         <div>
        //             <div className='our-vision d-none d-md-block'>
        //                 <h3 className='text-center'>Our Vision</h3>
        //                 <div className='col-md-12'>
        //                     <div className='container'>
        //                         <div className='row'>
        //                             <div className='col-md-6 p-2'>
        //                                 <div className='our1' style={{height:"425px"}}>
        //                                     <h3 className='mb-3'>MISION</h3>
        //                                     <p>Do you have aspirations of purchasing property or launching the start-up of your dreams, yet find yourself hindered by a lack of financial resources? Or perhaps you're looking to acquire your ideal car or embark on an international family holiday, but the necessary funds seem just out of reach?</p>

        //                                     <p>SriStar Gold Company offers a seamless solution to bridge the gap towards achieving your goals swiftly. We specialize in purchasing Gold and other Precious Metals, providing you with a trustworthy and efficient avenue to liquidate your assets. By selling your Gold to us, you can take advantage of the current market rates and receive a fair and immediate return on your investment.</p>

        //                                     <p>We cater to a wide range of gold assets. Whether your gold items are brand new, gently worn, or even if you possess gold in the form of scrap, we are ready to extend a competitive offer. Our process is designed to be straightforward and transparent, ensuring that you can convert your gold into cash with ease and confidence. Let SriStar Gold Company be the key to unlocking your financial potential and turning your dreams into reality.</p>

        //                                 </div>
        //                             </div>
        //                             <div className='col-md-6 p-2'>
        //                                 <div className='our1' style={{height:"425px"}}>
        //                                     <h3 className='mb-3'>Vision</h3>
        //                                     <ul>
        //                                         <li>Besides the growth of the business, SriStar Gold Company wants India to develop as well. While we plan to flourish throughout this nation, we will ensure that this country enriches with gold, and becomes prosperous enough to end Gold Import permanently. So, we are working towards making this nation the Gold Leader of the world.</li>
        //                                         <br />
        //                                         <li>Gold Loan is injurious to wealth. We intend to erase the concept of Gold Loan from India. When Gold is Pledged, there is a chance of losing both the money and the Gold Assets. So, SriStar Gold Company plans to make sure that every Indian is financially secure and the Gold is recycled and well-circulated within the country</li>
        //                                         <br />
        //                                         <li>Sristar Gold Company will establish India as the World Gold Leader by marking its footprint all across this nation. We aim to set up 100 Franchises by the year 2022 in Tier 2, Tier 3, and Tier 4 Small Towns as well as Cities, with your support</li>
        //                                     </ul>
        //                                 </div>
        //                             </div>
        //                             <div className='col-md-6 p-2'>
        //                             <div className='our1' style={{height:"300px"}} >
        //                                     <h3 className='mb-5'>We Protect you from Monetary Crisis</h3>
        //                                     <p>We offer you maximum cash for your gold and other valuable metals. Besides, we give you the highest gold release value for your pledged gold.</p>
        //                                     <ul>
        //                                         <li>No matter what you require cash for, SriStar Gold Company fixes all your financial problems. Whether you need to pay a large medical bill or educational expenses, or anything else, you need to worry.</li>
        //                                         <br />
        //                                         <li>Our services will cater to all of your requirements</li>
        //                                        </ul>
        //                                 </div>
        //                             </div>
        //                             <div className='col-md-6 p-2'>
        //                             <div className='our1' style={{height:"300px"}}>
        //                                     <h3 className='mb-4'>We Care for your Pride</h3>
        //                                     <p>SriStar Gold Company is the only gold dealer to offer you Gold Jewellery on rent @ 1 day for a nominal amount of money.</p>
        //                                     <ul>
        //                                         <li>Gold is counted as a Symbol of Status and Wealth in India. Yet sadly, wearing a new piece of gold jewellery on all the events is not possible for most of us. But not anymore!</li>
        //                                         <br />
        //                                         <li>Rent your favourite Gold Jewellery from our huge collection for each and every occasion</li>
        //                                        </ul>
        //                                 </div>
        //                             </div>
        //                             <div className='col-md-6 p-2'>
        //                             <div className='our1' style={{height:"330px"}}>
        //                                     <h3 className='mb-4'>We Help in Boosting the Indian Economy</h3>
        //                                     <p>We sell Indian made Gold Jewellery at very reasonable rates.</p>
        //                                     <ul>
        //                                         <li>SriStar Gold Company does not import any jewellery. Everything we sell is Made In India. In this manner, the amount we spend on buying Gold as well as the revenue we generate, both stay within the country.</li>
        //                                         <br />
        //                                         <li>We encourage fellow Indians to become Self-Reliant through our Franchise</li>
        //                                        <br/>
        //                                        <li>We offer you the golden opportunity of securing your and our country’s future altogether. The economy of India will improve with the rise of more successful entrepreneurs</li>
        //                                        </ul>
        //                                 </div>
        //                             </div>
        //                             <div className='col-md-6 p-2'>
        //                             <div className='our1' style={{height:"330px"}}>
        //                                     <h3 className='mb-4'>We have Career Opportunities for a number of positions</h3>
        //                                     <ul>
        //                                         <li>With the business growing gradually, SriStar Gold Company is constantly in need of Field Executives.</li>
        //                                         <br />
        //                                         <li>We also require Business Managers, Telecallers, Branch Managers, Accountants, Content Writers, Graphic Designers and so on.</li>
        //                                        <br/>
        //                                        <li>Through providing employment scopes, we are contributing to the economy of India.</li>
        //                                        </ul>
        //                                 </div>
        //                                 <br/>
        //                             </div>

        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>

        // <Vision />
        //         </div>
        <section className='vision-section'>
            <div className='container'>
                <div className='vision-cont'>
                    <div className="sub-hading"><h2 className="section-sub-heading">Our Mission & Vision</h2></div>
                <div className='vison-grid-cont'>
                    <div className='vsn-grids-outer'>
                        <div className='vsn-grids'>
                            <h4>Mission</h4>
                            <p>Do you have aspirations of purchasing property or launching the start-up of your dreams, yet find yourself hindered by a lack of financial resources? Or perhaps you're looking to acquire your ideal car or embark on an international family holiday, but the necessary funds seem just out of reach?

                            </p>
                            <p>SriStar Gold Company offers a seamless solution to bridge the gap towards achieving your goals swiftly. We specialize in purchasing Gold and other Precious Metals, providing you with a trustworthy and efficient avenue to liquidate your assets. By selling your Gold to us, you can take advantage of the current market rates and receive a fair and immediate return on your investment.

                            </p>
                            <p>We cater to a wide range of gold assets. Whether your gold items are brand new, gently worn, or even if you possess gold in the form of scrap, we are ready to extend a competitive offer. Our process is designed to be straightforward and transparent, ensuring that you can convert your gold into cash with ease and confidence. Let SriStar Gold Company be the key to unlocking your financial potential and turning your dreams into reality.

                            </p>
                        </div>
                    </div>
                    <div className='vsn-grids-outer'>
                        <div className='vsn-grids'>
                            <h4>Vision</h4>

                            <p>Besides the growth of the business, SriStar Gold Company wants India to develop as well. While we plan to flourish throughout this nation, we will ensure that this country enriches with gold, and becomes prosperous enough to end Gold Import permanently. So, we are working towards making this nation the Gold Leader of the world.
                            </p>
                            <p>Gold Loan is injurious to wealth. We intend to erase the concept of Gold Loan from India. When Gold is Pledged, there is a chance of losing both the money and the Gold Assets. So, SriStar Gold Company plans to make sure that every Indian is financially secure and the Gold is recycled and well-circulated within the country
                            </p>
                            <p>Sristar Gold Company will establish India as the World Gold Leader by marking its footprint all across this nation. We aim to set up 100 Franchises by the year 2022 in Tier 2, Tier 3, and Tier 4 Small Towns as well as Cities, with your support
                            </p>

                        </div>
                    </div>
                    <div className='vsn-grids-outer'>
                        <div className='vsn-grids'>
                            <h4>We Protect you from Monetary Crisis</h4>
                            <p>We offer you maximum cash for your gold and other valuable metals. Besides, we give you the highest gold release value for your pledged gold.</p>
                            <ul>
                                <li><span>No matter what you require cash for, SriStar Gold Company fixes all your financial problems. Whether you need to pay a large medical bill or educational expenses, or anything else, you need to worry.
                                </span></li>
                                <li><span>Our services will cater to all of your requirements</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='vsn-grids-outer'>
                        <div className='vsn-grids'>
                            <h4>We Care for your Pride</h4>
                            <p>SriStar Gold Company is the only gold dealer to offer you Gold Jewellery on rent @ 1 day for a nominal amount of money.</p>
                            <ul>
                                <li><span>Gold is counted as a Symbol of Status and Wealth in India. Yet sadly, wearing a new piece of gold jewellery on all the events is not possible for most of us. But not anymore!
                                </span></li>
                                <li><span>Rent your favourite Gold Jewellery from our huge collection for each and every occasion
                                </span></li>
                            </ul>
                        </div>
                    </div>
                    <div className='vsn-grids-outer'>
                        <div className='vsn-grids'>
                            <h4>We Help in Boosting the Indian Economy</h4>
                            <p>We sell Indian made Gold Jewellery at very reasonable rates.</p>
                            <ul>
                                <li><span>SriStar Gold Company does not import any jewellery. Everything we sell is Made In India. In this manner, the amount we spend on buying Gold as well as the revenue we generate, both stay within the country.
                                </span></li>
                                <li><span>We encourage fellow Indians to become Self-Reliant through our Franchise
                                </span></li>
                                <li><span>We offer you the golden opportunity of securing your and our country’s future altogether. The economy of India will improve with the rise of more successful entrepreneurs
                                </span></li>
                            </ul>
                        </div>
                    </div>
                    <div className='vsn-grids-outer'>
                        <div className='vsn-grids'>
                            <h4>We have Career Opportunities for a number of positions
                            </h4>
                            <p>With the business growing gradually, SriStar Gold Company is constantly in need of Field Executives.
                            </p>
                            <p>We also require Business Managers, Telecallers, Branch Managers, Accountants, Content Writers, Graphic Designers and so on.
                            </p>
                            <p>Through providing employment scopes, we are contributing to the economy of India.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section >
    )
}

export default OurVision
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import banglore from "../Images/banglore.jpg"
import manglore from "../Images/manglore.jpg"
import shivamogga from "../Images/shivamogga.jpg"
import davanagere from "../Images/davangere.jpg"
import raichur from "../Images/raichur.jpg"
import hubli from "../Images/hubli.png"
import Haveri from "../Images/haveri.png"
import channai from "../Images/channai.jpg"
import Hybd from "../Images/hyd.png"
import CardSliding from './CardSliding';

export default function CardSlider() {
  return (
    <>
      <section className='multi-slider-secition'>
        <div className='container '>
          <div className='sub-hading'>
            <h2>Branches</h2>
            <div className='swiper-cont'>
              <Swiper
                slidesPerView={4}
                spaceBetween={20}
                centeredSlides={false}
                pagination={{
                  clickable: false,
                }}
                navigation={true}
                modules={[Pagination, Autoplay]}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  400:{
                    slidesPerView:2,
                  },
                  639: {
                    slidesPerView: 3,
                  },
                  865:{
                    slidesPerView:4
                  },
                  1000:{
                    slidesPerView:5
                  },
                  1500:{
                    slidesPerView:6
                  },
                  1700:{
                    slidesPerView:7
                  }
                }}
                className="mySwiper-container"
              >
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/bvofVfC9jyUMLjro9'>
                    <img className='img-fluid' src={banglore} alt='banglore' /></a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/jf8r8w8mDnsCmasi9'>
                    <img className='img-fluid' src={channai} alt='banglore' /></a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/aQvZf8Ws2hj2TeSq8'>
                    <img className='img-fluid' src={shivamogga} alt='banglore' /></a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/to8VG49JcksvDzMd9'>
                    <img className='img-fluid' src={manglore} alt='banglore' /></a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/cHkE6kUfK3m4yRxd7'>
                    <img className='img-fluid' src={davanagere} alt='banglore' /></a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/oGhtWYQmHytXSpzKA'>
                    <img className='img-fluid' src={raichur} alt='banglore' /></a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/BddP9SRkPL5xgsFW9'>
                    <img className='img-fluid' src={hubli} alt='banglore' /></a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/QRs8JhF2ugwgjAbe9'>
                    <img className='img-fluid' src={Haveri} alt='banglore' /></a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://maps.app.goo.gl/L95m7TWVHLnFaRSQA'>
                    <img className='img-fluid' src={Hybd} alt='Hydrabad' /></a>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {/* <CardSliding /> */}
    </>
  );
}

import React, { useState, useRef } from 'react';


function BranchesPage() {

    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const tamilnaduRef = useRef(null);

    function toggleSwitch(switchId, switchValue) {
        var switchElement = document.getElementById(switchId);
        switchElement.checked = switchValue; // Set the switch state based on the argument
        setIsSwitchOn(switchValue); // Update the state
        if (switchValue) {
            // Scroll to Tamil Nadu section when the switch is on
            window.scrollTo({
                top: tamilnaduRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    }
    return (
        <div>
            <div className='container'>
                <div className='top-branchs'>
                    <div className='row v-gap-30'>
                        <div className='col-md-6'>
                            <div className='branch-box'>

                                <div className='ourBranchs'>
                                    <h3 className=''>OUR BRANCHES</h3>
                                    {/* <div className='branch-btns'>
                                        <button type="submit" onClick={() => toggleSwitch('darkSwitch', false)}>KARNATAKA</button>
                                        <span style={{ paddingLeft: "12px" }}></span>
                                        <button type="submit" onClick={() => toggleSwitch('darkSwitch', true)}>TAMIL NADU</button>
                                    </div> */}
                                    {/* <div className="d-flex justify-content-center custom-control custom-switch " >
                                                <input type="checkbox" className="custom-control-input" id="darkSwitch" />
                                                <label className="custom-control-label" for="darkSwitch"></label>
                                            </div> */}

                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>Hyderabad</h3>
                                                <p>Vajra Residency</p>
                                                <p>203 A Block 1st Floor, Musheerabad Main Road, Bakaram, Kavadiguda, Hyderabad, Telangana 500080 Opposite Metro Pillar Number 1073</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/NVfPL7JUCGLvkNgh7'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.941061960208!2d78.49507477501078!3d17.414615883477186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99ed2c37a49f%3A0x532640615a1e00e1!2sVajra%20Residency%2C%20Musheerabad%20Main%20Rd%2C%20Bakaram%2C%20Kavadiguda%2C%20Hyderabad%2C%20Telangana%20500080!5e0!3m2!1sen!2sin!4v1710668316231!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>BENGALURU</h3>
                                                <p>18th Cross, Malleshwaram</p>
                                                <p>280/1, Anniamma Arcade, Sampige Road, 18th Cross Rd, Malleshwaram, Bengaluru, Karnataka 560003</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/bvofVfC9jyUMLjro9'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.4293233636236!2d77.5713097!3d13.008309899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae162da7d2bdd1%3A0x5e253f56dcb22d40!2sAnniamma%20Arcade%2C%2018th%20Cross%20Rd%2C%20Ranganathapura%2C%20Malleshwara%2C%20Bengaluru%2C%20Karnataka%20560003!5e0!3m2!1sen!2sin!4v1701346250310!5m2!1sen!2sin" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>MYSURU</h3>
                                                <p>Sunnadakeri 4th cross</p>
                                                <p>No. 912 Susheel Building, 2nd floor, Sunnadakeri 4th cross, N S Road, Opp Nakshtra Complex, Mysuru, 570024</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/ojzM3nLeiVUwVai28'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3898.231033655112!2d76.64438247506521!3d12.30022238795685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDE4JzAwLjgiTiA3NsKwMzgnNDkuMSJF!5e0!3m2!1sen!2sin!4v1702554689928!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>MANGALORE</h3>
                                                <p>Balmatta Road </p>
                                                <p>Beauty Plaza Building, No. 14-4-501/24, 3rd Floor, Balmatta Road, Mangalore- 575001</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/to8VG49JcksvDzMd9'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.590674860451!2d74.84273277330134!3d12.86969351710792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35b30cbab81cb%3A0x76c49c1eebd93a5b!2sSriStar%20Gold%20Company%20-%20Mangalore!5e0!3m2!1sen!2sin!4v1701912764518!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>DAVANAGERE</h3>
                                                <p>Chigateri Hospital Road</p>
                                                <p>#29/9 Chigateri Hospital Road, Opp. Corporation Bank Near Vidyarti Bhavana Circle, Karnataka - 577002</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/cHkE6kUfK3m4yRxd7'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3863.447231128653!2d75.91674217510338!3d14.458989686010746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDI3JzMyLjQiTiA3NcKwNTUnMDkuNSJF!5e0!3m2!1sen!2sin!4v1702555219115!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>HAVERI</h3>
                                                <p>Hosamani Siddappa Circle</p>
                                                <p>Gudagar Complex, 1st Floor Hosamani Siddappa Circle Beside KGP, Jewelry Haveri - 581110</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/QRs8JhF2ugwgjAbe9'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3857.665216815006!2d75.39656687334276!3d14.787877572467673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb96924dede49c1%3A0x9e9aca1b164a78b8!2sSIDDAPPA%20CIRCLE!5e0!3m2!1sen!2sin!4v1701913809792!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>HUBLI</h3>
                                                <p>Tirumala Trade Centre</p>
                                                <p>Shop No 40, Ground floor, Tirumala Trade Centre, Neeligin Road, Hubli, Karnataka 580029</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/Fj4mi7DWxNYwoJNFA'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3847.468001776918!2d75.135058075121!3d15.351119985229072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTXCsDIxJzA0LjAiTiA3NcKwMDgnMTUuNSJF!5e0!3m2!1sen!2sin!4v1702555349767!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>RAICHUR</h3>
                                                <p>Gunj Road</p>
                                                <p>12-10-18/1A, 1st Floor, Gunj Road,Near Vaikuntam Complex, Raichur - 584101</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/oGhtWYQmHytXSpzKA'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3872.576293040613!2d75.56706467509339!3d13.924255886485833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDU1JzI3LjMiTiA3NcKwMzQnMTAuNyJF!5e0!3m2!1sen!2sin!4v1702555502070!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>SHIVAMOGGA</h3>
                                                <p>NT Road, Muneshwara Building</p>
                                                <p>#05, NT Road, Muneshwara Building, Opposite to Karnataka Bank, Near Tempo Stand, Shivamogga 577202</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/aQvZf8Ws2hj2TeSq8'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3872.6567598716024!2d75.56402957332313!3d13.919453093393447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbba8de921d9057%3A0xbce8b1919bd53614!2sNT%20Rd%2C%20Savi%20Palya%2C%20KR%20Puram%2C%20Shivamogga%2C%20Karnataka%20577202!5e0!3m2!1sen!2sin!4v1701913606447!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='branchs-grid'>
                                <div className='row no-gutters'>
                                    <div className='col-md-5'>
                                        <div className='branches-outer'>
                                            <div className='brnch-text'>
                                                <h3>CHENNAI</h3>
                                                <p>Mount Road</p>
                                                <p>No 9, JV White House, 1st Floor, Blackers Road, Anna Salai, Chennai, Tamil Nadu 600002</p>

                                            </div>
                                            <a className='text-decoration-none' href='https://maps.app.goo.gl/jf8r8w8mDnsCmasi9'>
                                                <button type="submit">Get directions</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='branch-ifreame'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3886.4918824855918!2d80.26828157507813!3d13.06798248725631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA0JzA0LjciTiA4MMKwMTYnMTUuMSJF!5e0!3m2!1sen!2sin!4v1702555711511!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='avalilable-area'>
                <div className='container'>
                    <div class="sub-hading"><h2>OTHER<strong> SERVICABLE</strong> AREAS</h2></div>
                    <div className='inner-area-avl' ref={tamilnaduRef}>
                        <div className='row v-gap-30'>
                            <div className='col-md-4 col-12 '>
                                <div className='col-others'>
                                    <div className='subbranches-outer'>
                                        <h3> BIDAR</h3>
                                    </div>
                                    <div className='iFreme-cont'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121488.11835089335!2d77.34688371403578!3d17.90864575639416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcec722d6908ce5%3A0xb9a71b6e00adff6c!2sBidar%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1703225839789!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15562.867348790429!2d77.3838303!3d12.79688325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae46188848a9cb%3A0xb78bbee238936b47!2sBidadi%2C%20Karnataka%20562109!5e0!3m2!1sen!2sin!4v1701408258424!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 '>
                                <div className='col-others'>
                                    <div className='subbranches-outer'>
                                        <h3> KOLAR</h3>
                                    </div>
                                    <div className='iFreme-cont'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31084.138180565446!2d78.1260753!3d13.1297385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3badf08b9595accd%3A0x43ef935427fdf3d0!2sKolar%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1701413434699!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 '>
                                <div className='col-others'>
                                    <div className='subbranches-outer'>
                                        <h3> COIMBATORE</h3>
                                    </div>
                                    <div className='iFreme-cont'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125322.50873824842!2d76.967235!3d11.013968899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859af2f971cb5%3A0x2fc1c81e183ed282!2sCoimbatore%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1701413588280!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BranchesPage
import React from 'react'
import Navbars from '../Utility/Navbars'
import PartnerForm from '../Partner/PartnerForm'
// import table1 from "../Images/table.png"
// import table2 from "../Images/table2.png"
import table1 from "../Images/FTable.jpg"
import Footer from '../Home/Footer'

function Partners() {
  return (
    // <div>
    //   <div className='bg-partners'>
    //     <Navbars />
    //     <div className='col-md-12'>
    //       <div className='container '>
    //         <img src={table1} alt='expences' className='image-responsive w-100' />

    //         <h2 className='text-center' style={{ color: "#441e39", fontWeight: "900" }}>Let's Be Partners</h2>
    //         <br />
    //         <h1 className='text-center' style={{}}>Franchise Opportunity</h1>
    //         <p className='mx-auto' style={{ fontSize: "14px" }}>Do you want an all-in-one profession that gives you Independence, Flexibility, Satisfaction, Security and a Good Pay altogether? But, the corporate 9 to 5 jobs can’t offer you everything. So, have you been thinking about starting your own business? Then you must understand that building your own company from scratch is complicated and time-consuming.

    //           <br />Establishing a business requires many elements such as management skills, knowledge of workings in the industry, proper planning and execution, along with arranging a large sum of money

    //           We have an offer that makes everything easier for you. And if you join us, you can avoid all the risk factors and enjoy fixed income monthly</p>
    //         <br />
    //         <h1 className='text-center' style={{}}>Our Proposal</h1>
    //         <p className='mx-auto' style={{ fontSize: "14px" }}>Now you have the Golden Opportunity to secure your finances by becoming our Franchise. Yes, you can be a Gold Trader as well.<br />
    //           We want to set up 100 stores by the end of 2022 in Tier 2, Tier 3, and Tier 4 small towns and cities. So, we are in search of people with the zeal of starting and operating a successful business<br />
    //           We follow the FOCO Model (Franchise Owned Company Operated) which has proven to be safe and successful. Also, we have a simple and one-time investment plan with assured monthly returns.<br />
    //           You receive a guaranteed return of 38% to 45% annually irrespective of the business outcome along with profit sharing. So, you get a monthly fixed return of at least ₹33,333 every month without putting almost no effort<br />
    //           We need a store with an area of 300 to 500 sq. ft. It’s okay if you don’t have your own space. We can rent a business spot together. In that case, you pay the rental deposit and we take care of the monthly rents.<br />
    //           Your one-time investment to start your own company is ₹ 12 lacs + Rental Deposit.
    //         </p>

    //         <br /><br />
    //         <img src={table1} alt='expences' className='image-responsive w-100' />
    //         <br />
    //         <br />

    //         <h1 className='text-center' style={{}}>Benefits</h1>
    //         <p className='text-center' style={{ fontSize: "14px", fontWeight: "bolder" }}>Working with us gives you many benefits</p>
    //         <ul className='mx-auto' style={{ fontSize: "14px" }}>
    //           <li>Most importantly you get the scope of making your own fate rather than working under others</li>
    //           <li>Besides, we are the first and only Gold Company that guarantees assured returns on your investment from the very 1st month itself. You can see the results yourself within a few weeks of launch</li>
    //           <li>Also, you get the chance to enter the industry that has ZERO recession. The Gold industry is an evergreen and constantly growing sector. It is considered as the least affected industry even during inflation.</li>
    //           <li>You earn at least ₹ 33,333 every month without even being physically present. Moreover, you can access real-time information about each business transaction made at your franchise</li>
    //           <li>We work in FOCO Model that ensures both you and we are free of risks. This partnership model guarantees Return on Investment irrespective of profit or loss</li>
    //         </ul>

    //         <h1 className='text-center' style={{}}>Why Us</h1>
    //         <p className='text-center' style={{ fontSize: "14px", fontWeight: "bolder" }}>Advantages of doing business with us</p>
    //         <ul className='mx-auto' style={{ fontSize: "14px" }}>
    //           <li>Mr Srikanth V. Nalige and Mr Manjappa Bidaragaddi founded Sristar Gold Company in 2019. In this time span, we have been successful in launching and operating 10+ stores in South India with our revenue growing every day. We plan to expand all across India with your help</li>
    //           <li>Our founders have been active in the gold industry for almost 10 years now and have vast knowledge about all the workings. All these years of experience is one of the key reasons for our success</li>
    //           <li>Our FOCO Model allows you to continue with your passion and hobbies along with your business. You receive fixed monthly returns even if you’re not present in the franchise outlet</li>
    //         </ul>

    //         <h1 className='text-center' style={{}}>Process</h1>
    //         <p className='text-center' style={{ fontSize: "14px" }}>We take around 30 to 45 days to set up and launch the franchise. After you finalise the deal with us, it’s our duty to handle everything and ensure that the operations in the new franchise store start within the next month</p>
    //       </div>

    //       <br />
    //       <div className='col-md-11 mx-auto'>
    //         <img src={table2} alt='expences' className='image-responsive w-100 mb-3' />
    //         <p style={{ fontSize: "14px" }}>The operations in your Franchise begins from the launch day itself<br />
    //           For any other information please call +91 6366333444.<br />
    //           You can also visit the nearest Sristar Gold Company branch to you</p>


    //       </div>

    //       <h1 className='text-center mt-5 pb-2' style={{}}>Franchise Application Form</h1>
    //       <PartnerForm />
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
    <>
      <Navbars />
      <div className='inner-pages'>
        <section className='partners-section'>
          <div className='container'>
            <div className='partner-table'>
              <img src={table1} alt='expences' className='img-fluid' />
            </div>
          </div>
          <div className='partners-form'>
            <div className='container'>
              <div class="sub-hading"><h2>Franchise<strong> Application</strong>  Form</h2></div>
              <PartnerForm />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default Partners
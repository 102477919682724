import React from 'react'
import Navbars from '../Utility/Navbars'
import Form from '../Calculate/Form'
import create from "../Images/create.png"
import Footer from '../Home/Footer'


function Calculator() {
  return (
    // <div>
    //   <div className='bg-cal'>
    //     <Navbars />
    //     <br />
    //     <div className='col-md-11 mx-auto d-none d-md-block' style={{ height: "250px", overflow: "hidden" }}>
    //       <img src={create} alt='calculate' className='image-responsive w-100' />
    //     </div>
    //     <div className='col-md-11 mx-auto d-block d-md-none' style={{ overflow: "hidden" }}>
    //       <img src={create} alt='calculate' className='image-responsive w-100' />
    //     </div>
    //     <br />

    //     <Form />
    //     <br /><br /> <br /><br /> <br /><br />
    //   </div>
    //   <Footer />
    // </div>
    <>
      <Navbars />

      <div className='inner-pages calc-main'>
        <section className='calc-section'>
          <div className='container'>
            <div className='cal-outer'>
              <div className='row v-gap-15'>
                <div className='col-md-6 col-12'>
                  <div className='img-calc'>
                    <img src={create} alt='calculate' className='img-fluid' />
                  </div>
                </div>
                <div className='col-md-6 col-12'>
                  <div className='cal-form'>
                    <Form />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default Calculator
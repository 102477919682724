import React from 'react';

const ScrollDownButton = () => {
  const handleScroll = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className="scroll-down-button" onClick={handleScroll}
    >
      <div className='scroll-button'>
        <button type="submit" className="">CONTACT</button>
      </div>
    </div>
  );
};

export default ScrollDownButton;

import React from 'react'
import webuy from "../Images/webuy.jpg"
import rentgold from "../Images/rent.jpg"
import wesell from "../Images/wesellgold.jpg"
import repledge from "../Images/repludge.jpg"
import pledge from "../Images/repledge1.jpg"
import doorstep from "../Images/doorstep.jpg"
import stonevalue from "../Images/stonevalue.jpg"
import goldcard from "../Images/goldReturnCard.png"
import Service from './Service'

function ServicesPage() {
    return (
        // <div>
        //     <div className='row'>
        //         <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
        //             <img src={webuy} alt='we-buy-gold' className='image-responsive' style={{ height: "400px" }} />
        //         </div>
        //         <div className='col-md-8 d-none d-md-block'>
        //             <div className='col-md-12'>
        //                 <h2>We Buy Gold</h2>
        //                 
        //                 <p>We are the most trusted second-hand gold buyer in Karnataka and Tamil Nadu. We promise to make your experience of selling gold fully satisfying.</p>
        //                 <p>We are the only gold buyers to not only pay you the price for your metal but also offer you the value of stones. Our expert professionals use the latest technologies and advanced methods to ensure that your metals are evaluated properly in a secure, simple, and swift manner.</p>
        //                 <p>All you need to do is bring your gold or other valuable metals to our office, along with some required documents, and we take care of the rest. </p>
        //                 
        //                 <div className='btn-srvc'>
        //                     <button type="button">Learn More</button>
        //                 </div>
        //             </div>
        //         </div>

        //         <div className='col-md-8 d-none d-md-block'>
        //             <div className='col-md-12'>
        //                 <h2>We Sell Gold</h2>
        //                 
        //                 <p>Sristar Gold Company is the Gold Point where you can purchase all kinds of gold jewellery at the lowest prices all across Karnataka and Tamil Nadu. We take proper measures to ensure that we sell the best quality of gold.</p>
        //                 <p>Our expert team evaluates the gold thoroughly, using the latest technologies to check the purity of the metal. All the gold jewellery we sell are hallmarked and of great quality. We bet our rates are lower than any other gold trader. Also, if you’re unable to come to our office to make the purchase, we offer doorstep services</p>
        //                 <p>
        //                     Our trusted team of executive can deliver your favourite jewellery to your home whenever you want. </p>
        //                 
        //                 <div className='btn-srvc'>
        //                     <button type="button">Learn More</button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
        //             <img src={wesell} alt='we-buy-gold' className='image-responsive' style={{ height: "400px" }} />
        //         </div>

        //         <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
        //             <img src={repledge} alt='we-buy-gold' className='image-responsive'  />
        //         </div>
        //         <div className='col-md-8 d-none d-md-block'>
        //             <div className='col-md-12' >
        //                 <h2 className=''>We Repledge Gold</h2>
        //                 
        //                 <p className=''>We tailor-make our services as per your needs. Selling gold is a big decision that has to be made after thinking very carefully. Instead, you can repledge your gold again at today’s rate. Get extra cash, plus extra time to release your Gold.</p>
        //                 <p className=''>First, we help you release your pledged gold from banks, NBFCs, financiers and pawn shops by paying the interests. After the release, we take your gold to our partner bank for repledging at the current gold rate. This way you don’t only get more money for the same gold, but also get extra time to get your metal back.</p>
        //                 <p className=''>We pay an upfront advance amount to NBFCs, banks, financiers and pawn shops to release you from your gold loan. Then we pledge your gold again at the best rate. </p>
        //                 <p className=''>We take the released gold to our office for purity evaluation in a fast, safe and simple way. Checking the purity is necessary so that we can offer you the best deals in return of your gold. After this, we come up with an estimation for your metal.</p>
        //                 <p className=''>After repledging your metal at the current rate, we deduct the sum pay for the release. So, you receive the rest of the cash, without any extra effort.</p>
        //                 
        //                 <div className='btn-srvc '>
        //                     <button type="button">Learn More</button>
        //                 </div>
        //             </div>
        //         </div>

        //         <div className='col-md-8 d-none d-md-block'>
        //             <div className='col-md-12' >
        //                 <h2 >We Give Gold On Rent</h2>
        //                 
        //                 <p>We are the very first gold buyer to present you with the concept of Gold Jewellery Rental. You get to choose from our huge collection of unique gold jewellery.</p>
        //                 <p>We offer the above-mentioned Gold Jewellery on rent for an agreed period after following certain security norms. On clearing our safety check, you are good to go for the next steps of our rental service.</p>
        //                 <p>This way, you can lease your favourite jewellery from our extensive range of unique collection, from one day to three days! Although, you must ensure keeping it safe during the entire rental period.</p>
        //                 
        //                 <div className='btn-srvc'>
        //                     <button type="button">Learn More</button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
        //             <img src={rentgold} alt='we-buy-gold' className='image-responsive'  />
        //         </div>

        //         <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
        //             <img src={pledge} alt='we-buy-gold' className='image-responsive'  />
        //         </div>
        //         <div className='col-md-8 d-none d-md-block'>
        //             <div className='col-md-12' >
        //                 <h2 className='' >We Release Pledged Gold</h2>
        //                 
        //                 <p className=''>We are committed to providing you with the best experience of releasing pledged gold through our unmatched dynamic services and highest gold release value</p>
        //                 <p className=''>We offer an upfront advance payment to banks, pawn shops, NBFCs, and financiers to release the gold you pledged. Then, we buy the same at the highest price based on current market rates</p>
        //                 <p className=''>We take the released gold to our office for purity evaluation in a fast, safe and simple way. Checking the purity is necessary so that we can offer you the best deals in return of your gold. After this, we come up with an estimation for your metal.</p>
        //                 <p className=''>You receive the balance after deducting the advance amount we pay beforehand for the release. Thus, you get cash for gold rather than losing all your gold for nothing.</p>
        //                 
        //                 <div className='btn-srvc '>
        //                     <button type="button">Learn More</button>
        //                 </div>
        //             </div>
        //         </div>

        //         <div className='col-md-8 d-none d-md-block'>
        //             <div className='col-md-12' >
        //                 <h2>Doorstep  Service</h2>
        //                 
        //                 <p>If you are unable to visit our store then you can also sell your gold conveniently from your home. You can easily connect with our executives and follow their instructions to sell your gold.</p>
        //                 <p>The process is quick, easy, and also made secure. Based on your availability we visit your location with a valid identity. You can easily track the entire process of selling gold.</p>
        //                 <p>Our executive measures your gold and checks the purity using the latest technology and provides on-the-spot cash for your jewelry.</p>
        //                 
        //                 <div className='btn-srvc'>
        //                     <button type="button">Learn More</button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
        //             <img src={doorstep} alt='we-buy-gold' className='image-responsive'  />
        //         </div>

        //         <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
        //             <img src={stonevalue} alt='we-buy-gold' className='image-responsive' style={{ height: "400px" }} />
        //         </div>
        //         <div className='col-md-8 d-none d-md-block'>
        //             <div className='col-md-12'>
        //                 <h2>Stone Value For Your Jewellery</h2>
        //                 
        //                 <p>We  not only provide cash for your gold we also provide the stone value along with your jewelry. Upon checking your weight and purity of gold we also check your stone value with the latest equipment to provide the best price for your gold.</p>
        //                 <p>Our stone appraisal team to check the stone value along with your gold.</p>
        //                 <p>Once the evaluation is done we will provide a receipt that contains all the details of gold and stone value based on the current price. </p>
        //                 
        //                 <div className='btn-srvc'>
        //                     <button type="button">Learn More</button>
        //                 </div>
        //             </div>
        //         </div>

        //         <div className='col-md-8 d-none d-md-block'>
        //             <div className='col-md-12' >
        //                 <h2>Gold Returns Card</h2>
        //                 
        //                 <p>We are thrilled to introduce the enhanced Gold Returns Card, an exclusive privilege card offered exclusively by SriStar Gold.</p>
        //                 <p>With a generous 4-year validity period, this card unlocks a suite of benefits designed to enrich your journey in gold investment and ownership. It's not just a card, but a passport to a world of premium services, personalized offers, and priority access that SriStar Gold customers have come to expect.</p>
        //                 <p>Embrace the luxury of convenience and security as you navigate the precious metals market with confidence and class.</p>
        //                 
        //                 <div className='btn-srvc'>
        //                     <button type="button">Learn More</button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='col-md-4 d-none d-md-block' style={{ padding: "initial" }}>
        //             <img src={goldcard} alt='we-buy-gold' className='image-responsive' style={{ height: "400px" }} />
        //         </div>
        //     </div>
        //     <div className='d-block d-md-none'>
        //         <Service />
        //     </div>
        // </div>
        <>
            <section className='srvc-section'>
                <div className='container'>
                    <div className='grids-cont-service'>
                        <div className='service-grids'>
                            <div className='row'>
                                <div className='col-md-4 '>
                                    <div className='gold-srvc-img'>
                                        <img src={webuy} alt='we-buy-gold' className='img-fluid' />
                                    </div>
                                </div>
                                <div className='col-md-8 '>
                                    <div className='service-grids-text'>
                                        <h2>We Buy Gold</h2>
                                        <p>We are the most trusted second-hand gold buyer in Karnataka and Tamil Nadu. We promise to make your experience of selling gold fully satisfying.</p>
                                        <p>We are the only gold buyers to not only pay you the price for your metal but also offer you the value of stones. Our expert professionals use the latest technologies and advanced methods to ensure that your metals are evaluated properly in a secure, simple, and swift manner.</p>
                                        <p>All you need to do is bring your gold or other valuable metals to our office, along with some required documents, and we take care of the rest. </p>
                                        <div className='btn-srvc'>
                                            <button type="button">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='service-grids'>
                            <div className='row'>
                                <div className='col-md-8 '>
                                    <div className='service-grids-text'>
                                        <h2 className=''>We Release Pledged Gold</h2>
                                        <p className=''>We are committed to providing you with the best experience of releasing pledged gold through our unmatched dynamic services and highest gold release value</p>
                                        <p className=''>We offer an upfront advance payment to banks, pawn shops, NBFCs, and financiers to release the gold you pledged. Then, we buy the same at the highest price based on current market rates</p>
                                        <p className=''>We take the released gold to our office for purity evaluation in a fast, safe and simple way. Checking the purity is necessary so that we can offer you the best deals in return of your gold. After this, we come up with an estimation for your metal.</p>
                                        <p className=''>You receive the balance after deducting the advance amount we pay beforehand for the release. Thus, you get cash for gold rather than losing all your gold for nothing.</p>
                                        <div className='btn-srvc '>
                                            <button type="button">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 '>
                                    <div className='gold-srvc-img'>
                                        <img src={pledge} alt='we-buy-gold' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='service-grids'>
                            <div className='row'>
                                <div className='col-md-4 '>
                                    <div className='gold-srvc-img'>
                                        <img src={rentgold} alt='we-buy-gold' className='img-fluid' />
                                    </div>
                                </div>
                                <div className='col-md-8 '>
                                    <div className='service-grids-text'>
                                        <h2>We Give Gold On Rent</h2>
                                        <p>We are the very first gold buyer to present you with the concept of Gold Jewellery Rental. You get to choose from our huge collection of unique gold jewellery.</p>
                                        <p>We offer the above-mentioned Gold Jewellery on rent for an agreed period after following certain security norms. On clearing our safety check, you are good to go for the next steps of our rental service.</p>
                                        <p>This way, you can lease your favourite jewellery from our extensive range of unique collection, from one day to three days! Although, you must ensure keeping it safe during the entire rental period.</p>
                                        <div className='btn-srvc'>
                                            <button type="button">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='service-grids'>
                            <div className='row'>

                                <div className='col-md-8 '>
                                    <div className='service-grids-text'>
                                        <h2 className=''>We Repledge Gold</h2>
                                        <p className=''>We tailor-make our services as per your needs. Selling gold is a big decision that has to be made after thinking very carefully. Instead, you can repledge your gold again at today’s rate. Get extra cash, plus extra time to release your Gold.</p>
                                        <p className=''>First, we help you release your pledged gold from banks, NBFCs, financiers and pawn shops by paying the interests. After the release, we take your gold to our partner bank for repledging at the current gold rate. This way you don’t only get more money for the same gold, but also get extra time to get your metal back.</p>
                                        <p className=''>We pay an upfront advance amount to NBFCs, banks, financiers and pawn shops to release you from your gold loan. Then we pledge your gold again at the best rate. </p>
                                        <p className=''>We take the released gold to our office for purity evaluation in a fast, safe and simple way. Checking the purity is necessary so that we can offer you the best deals in return of your gold. After this, we come up with an estimation for your metal.</p>
                                        <p className=''>After repledging your metal at the current rate, we deduct the sum pay for the release. So, you receive the rest of the cash, without any extra effort.</p>
                                        <div className='btn-srvc '>
                                            <button type="button">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 '>
                                    <div className='gold-srvc-img'>
                                        <img src={repledge} alt='we-buy-gold' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='service-grids'>
                            <div className='row'>
                                <div className='col-md-4 '>
                                    <div className='gold-srvc-img'>
                                        <img src={wesell} alt='we-buy-gold' className='img-fluid' />
                                    </div>
                                </div>
                                <div className='col-md-8 '>
                                    <div className='service-grids-text'>
                                        <h2>We Sell Gold</h2>
                                        <p>Sristar Gold Company is the Gold Point where you can purchase all kinds of gold jewellery at the lowest prices all across Karnataka and Tamil Nadu. We take proper measures to ensure that we sell the best quality of gold.</p>
                                        <p>Our expert team evaluates the gold thoroughly, using the latest technologies to check the purity of the metal. All the gold jewellery we sell are hallmarked and of great quality. We bet our rates are lower than any other gold trader. Also, if you’re unable to come to our office to make the purchase, we offer doorstep services</p>
                                        <p> Our trusted team of executive can deliver your favourite jewellery to your home whenever you want. </p>
                                        <div className='btn-srvc'>
                                            <button type="button">Learn More</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='service-grids'>
                            <div className='row'>

                                <div className='col-md-8 '>
                                    <div className='service-grids-text'>
                                        <h2>Stone Value For Your Jewellery</h2>
                                        <p>We not only provide cash for your gold we also provide the stone value along with your jewelry. Upon checking your weight and purity of gold we also check your stone value with the latest equipment to provide the best price for your gold.</p>
                                        <p>Our stone appraisal team to check the stone value along with your gold.</p>
                                        <p>Once the evaluation is done we will provide a receipt that contains all the details of gold and stone value based on the current price. </p>
                                        <div className='btn-srvc'>
                                            <button type="button">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 '>
                                    <div className='gold-srvc-img'>
                                        <img src={stonevalue} alt='we-buy-gold' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='service-grids'>
                            <div className='row'>
                            <div className='col-md-4 '>
                                    <div className='gold-srvc-img'>
                                        <img src={doorstep} alt='we-buy-gold' className='img-fluid' />
                                    </div>
                                </div>
                                <div className='col-md-8 '>
                                    <div className='service-grids-text'>
                                        <h2>Doorstep Service</h2>
                                        <p>If you are unable to visit our store then you can also sell your gold conveniently from your home. You can easily connect with our executives and follow their instructions to sell your gold.</p>
                                        <p>The process is quick, easy, and also made secure. Based on your availability we visit your location with a valid identity. You can easily track the entire process of selling gold.</p>
                                        <p>Our executive measures your gold and checks the purity using the latest technology and provides on-the-spot cash for your jewelry.</p>
                                        <div className='btn-srvc'>
                                            <button type="button">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className='service-grids'>
                            <div className='row'>
                                <div className='col-md-8 '>
                                    <div className='service-grids-text'>
                                        <h2>Gold Returns Card</h2>
                                        <p>We are thrilled to introduce the enhanced Gold Returns Card, an exclusive privilege card offered exclusively by SriStar Gold.</p>
                                        <p>With a generous 4-year validity period, this card unlocks a suite of benefits designed to enrich your journey in gold investment and ownership. It's not just a card, but a passport to a world of premium services, personalized offers, and priority access that SriStar Gold customers have come to expect.</p>
                                        <p>Embrace the luxury of convenience and security as you navigate the precious metals market with confidence and class.</p>
                                        <div className='btn-srvc'>
                                            <button type="button">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 '>
                                    <div className='gold-srvc-img'>
                                        <img src={goldcard} alt='we-buy-gold' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServicesPage
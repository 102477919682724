import React from 'react'

function AboutPage() {
  return (
    // <div>

    //   <section className="text-center abt">
    //     <p className="first" style={{ color: "#E5CA75", fontSize: "29px", fontWeight: "700" }}>About Us</p>
    //     <div className="container ">
    //       <div className="row">
    //         <div className="col-md-7 mx-auto">
    //           <p className="lead" style={{ color: "#E5CA75" }}>
    //             SriStar Gold Company is the one-stop-solution if you are in need of quick cash or looking for profitable investments. We offer you the best deals because we understand your old and other precious metals are very important to you.
    //             <br /><br />
    //             Founded by Mr Srikanth V. Nalige and Mr Manjappa Bidaragaddi in 2019, SriStar Gold Company is an ISO Certified company. We aim to become the most trusted companion to everyone in India. We want to mark our footprints in each state of this country so that we can stand by your side in your critical times.
    //             <br /><br />
    //             Visit our 10+ offices located all across Karnataka and Tamil Nadu. Don’t worry if you cannot come to us; Just opt for our doorstep-service, and we will reach out to you.</p></div>

    //         <br />
    //         <br />

    //       </div>

    //     </div>
    //   </section>

    // </div>
    <section className="about-content">
      <div className='container'>
        <div class="shop-by-hero-bg">
          <div class="blob blob-five blob1 solid-blob"></div>
          <div class="blob blob-four blob2 solid-blob"></div>
        </div>
        <div className='abt-cont'>
          <h4>About Us</h4>
          <p>SriStar Gold Company is the one-stop-solution if you are in need of quick cash or looking for profitable investments. We offer you the best deals because we understand your old and other precious metals are very important to you.</p>

          <p>
            Founded by Mr Srikanth V. Nalige and Mr Manjappa Bidaragaddi in 2019, SriStar Gold Company is an ISO Certified company. We aim to become the most trusted companion to everyone in India. We want to mark our footprints in each state of this country so that we can stand by your side in your critical times.
          </p>

          <p>Visit our 10+ offices located all across Karnataka and Tamil Nadu. Don’t worry if you cannot come to us; Just opt for our doorstep-service, and we will reach out to you.</p>
        </div>
      </div>
    </section>
  )
}

export default AboutPage